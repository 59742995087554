<ng-container>
  <ng-container *ngIf="!imageCreatedInd" matTooltip="No existing certificate">&nbsp;</ng-container>
  <button
    *ngIf="imageCreatedInd"
    mat-icon-button
    matTooltip="Download certificate"
    matTooltipPosition="above"
    (click)="onDownloadCertificateClicked()"
  >
    <mat-icon class="mat-icon-download">download</mat-icon>
  </button>
  <!--                  manual certificate download-->
  <button
    *ngIf="hasManualCreateCerficateRole && !imageCreatedInd && extendedLogHeader?.isManualCertificateDownloadable"
    mat-icon-button
    matTooltip="Click to download Manual Certificate"
    matTooltipPosition="after"
    (click)="onDownloadManualCertificateClicked()"
  >
    <mat-icon class="mat-icon-download">download</mat-icon>M
  </button>
  <!-- hide this until we can use PDF -->
  <!-- <button
    *ngIf="isImageCreated"
    mat-icon-button
    matTooltip="Open certificate"
    matTooltipPosition="above"
    (click)="onViewCertificateClicked()"
  >
    <mat-icon>open_in_new</mat-icon>
  </button> -->
</ng-container>
