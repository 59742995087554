<ng-container>
  <ng-template [ngIf]="hasCurrentUserWriteAccess" [ngIfElse]="valueOnly">
    <a xpo-link (click)="onLinkClicked()" matTooltip="Search pro number">
      {{ params.value | proNumber }}
    </a>
  </ng-template>
  <ng-template #valueOnly>
    {{ params.value | proNumber }}
  </ng-template>
  <button mat-icon-button matTooltip="Open shipment details" matTooltipPosition="above" (click)="openShipmentDetails()">
    <mat-icon>open_in_new</mat-icon>
  </button>
</ng-container>
