import { DateHelper } from '@shared/classes/date-helper';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ManualReweighCertificate } from '@xpo-ltl/sdk-reweigh';
import { isEmpty as _isEmpty } from 'lodash';
import { decode } from 'typescript-base64-arraybuffer';

export class ReweighCertificateUtil {
  static downloadCertificate(
    aManualReweighCertificate: ManualReweighCertificate,
    aReweighAppNotificationService: ReweighAppNotificationService
  ) {
    if (!aManualReweighCertificate) {
      aReweighAppNotificationService.error('Empty response');
    } else {
      if (!_isEmpty(aManualReweighCertificate.base64Data)) {
        const lDecodedData: Uint8Array = decode(aManualReweighCertificate.base64Data);
        const file: Blob = new Blob([lDecodedData], { type: 'image/tiff;base64' });
        const link: HTMLAnchorElement = document.createElement('a');
        if (link != null) {
          link.href = window.URL.createObjectURL(file);
          const lFileName: string =
            aManualReweighCertificate.proNbr +
            '_' +
            DateHelper.getPortandTzFormattedDate(new Date(), DateHelper.DEFAULT_YYYYMMDD_HHMMSS);
          link.download = lFileName;
          link.click();
        }
      } else {
        aReweighAppNotificationService.error('Certificate data is empty');
      }
    }
  }
}
