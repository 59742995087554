import { ExtendedLiftScaleCalibrationHeader } from '@shared/classes/entities/extended-lift-scale-calibration-header';
import { ExtendedLiftScaleCalibrationHeaderUniq } from '@shared/classes/entities/extended-lift-scale-calibration-header-uniq';
import { ExtendedLogHeader } from '@shared/classes/entities/extended-log-header';
import { LogHeaderShipment } from '@xpo-ltl/sdk-reweigh';
import { List } from 'immutable';
import { ExtendedInspectionDimension } from './entities/extended-inspection-dimension';

export class Reweigh {
  static OPERATOR_FULL_NAME_COL_WIDTH_PX: number = 150;
  logHeader: ExtendedLogHeader | undefined;
  logHeaderShipment: LogHeaderShipment | undefined;
  liftScaleCalibrationHeaders: List<ExtendedLiftScaleCalibrationHeader> = List();
  liftScaleCalibrationHeadersUniq: List<ExtendedLiftScaleCalibrationHeaderUniq> = List();

  constructor() {}

  getLogHeaderId(): number {
    return this.logHeader?.logHeaderId;
  }

  getLogHeader() {
    return this.logHeader;
  }
}
