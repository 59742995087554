import { Component } from '@angular/core';
import { ReweighLogDetailsViewType } from '@shared/enums/reweigh-log-detail-view-type';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { OperatorLogDetail } from '@shared/classes/entities/operator-log-detail';
import { ProNumberHelper } from '@shared/classes/pro-number-helper/pro-number-helper';
import { ICellRendererParams } from 'ag-grid-community';
import { List } from 'immutable';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'reweigh-log-details-cell-renderer',
  templateUrl: './reweigh-log-details-cell-renderer.component.html',
  styleUrls: ['./reweigh-log-details-cell-renderer.component.scss'],
})
export class ReweighLogDetailsCellRendererComponent implements ICellRendererAngularComp {
  logDetails$: BehaviorSubject<List<OperatorLogDetail>> = new BehaviorSubject<Immutable.List<OperatorLogDetail>>(
    List()
  );
  logHeaderId: number;
  proNumberTxt: string;

  protected readonly ReweighLogDetailsViewType = ReweighLogDetailsViewType;

  agInit(params: ICellRendererParams): void {
    this.logDetails$.next(List(params.data.logDetail));
    if (params.data?.logHeaderId && !isNaN(+params.data?.logHeaderId)) {
      this.logHeaderId = params.data.logHeaderId;
    }
    if (params.data?.proNumberTxt) {
      this.proNumberTxt = ProNumberHelper.getStandardizedProNbr(params.data.proNumberTxt);
    }
  }

  refresh(): boolean {
    return false;
  }
}
