import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { XpoNotificationTemplate } from '@xpo-ltl/ngx-ltl-core';
import { ReweighStatusCd } from '@xpo-ltl/sdk-common';
import { CreateReweighCertificatesRqst, LogHeader, UpdateReweighLogHeaderRqst } from '@xpo-ltl/sdk-reweigh';
import { BehaviorSubject, forkJoin, from, Observable, of, TimeoutError } from 'rxjs';
import { catchError, finalize, mergeMap, tap, timeout } from 'rxjs/operators';

import { MatSnackBarRef } from '@angular/material/snack-bar';
import { FailedLogHeaderUpdateDialogComponent } from '@dialogs/failed-log-header-update-dialog/failed-log-header-update-dialog.component';
import { ErrorHelper } from '@shared/classes';
import { SnackbarDetailComponent } from '@shared/components/snackbar-detail/snackbar-detail.component';
import { ActionsEnum } from '@shared/enums';
import { LogHeaderUpdateError, LogHeaderUpdateInfo } from '@shared/interfaces';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { UserRoleService } from '@shared/services/user-role';

@Injectable({
  providedIn: 'root',
})
export class ReweighLogHeaderActionsService {
  // #region ActionPerformed
  private _actionPerformedSubject$: BehaviorSubject<ActionsEnum> = new BehaviorSubject<ActionsEnum>(null);
  actionPerformed$: Observable<ActionsEnum> = this._actionPerformedSubject$.asObservable();
  // #endregion

  // #region ActionInProgress
  private _actionInProgressSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  actionInProgress$: Observable<boolean> = this._actionInProgressSubject$.asObservable();
  get actionInProgress(): boolean {
    return this._actionInProgressSubject$.value;
  }
  // #endregion

  snackbarRef: MatSnackBarRef<XpoNotificationTemplate>;
  successMsg: string[];
  errorMsg: string[];

  constructor(
    protected reweighAppNotificationService: ReweighAppNotificationService,
    private reweighLogHeadersService: ReweighApiServiceWrapper,
    private dialog: MatDialog,
    private userRoleService: UserRoleService
  ) {}

  private setActionInProgress(value = true, action = ActionsEnum.ACTION_STARTED): void {
    this._actionPerformedSubject$.next(action);
    this._actionInProgressSubject$.next(value);
  }

  updateDisplayInd(logHeaders: LogHeader[], displayInd: boolean): void {
    this.validateCurrentUserHasWriteAccess('updateDisplayInd');
    this.setActionInProgress();

    const rqst = new UpdateReweighLogHeaderRqst();
    rqst.displayInd = displayInd;

    this.updateReweighLogHeader(logHeaders, rqst);
  }

  resubmitLogHeader(logHeaders: LogHeader[]): void {
    this.validateCurrentUserHasWriteAccess('resubmitLogHeader');

    this.setActionInProgress();

    const rqst = new UpdateReweighLogHeaderRqst();
    rqst.statusCd = ReweighStatusCd.RESUBMIT;

    this.updateReweighLogHeader(logHeaders, rqst);
  }

  createCertificates(logHeaders: LogHeader[]): void {
    this.validateCurrentUserHasWriteAccess('createCertificates');

    this.setActionInProgress();
    this.errorMsg = [];
    this.successMsg = [];
    this.createReweighCertificates(logHeaders)
      .pipe(
        finalize(() => {
          if (this.errorMsg.length) {
            this.snackbarRef = this.reweighAppNotificationService.open({
              message: 'The process did not complete successfully due to the following errors:',
              detailedMessageComponent: SnackbarDetailComponent,
              detailedMessageComponentData: this.errorMsg,
              status: 'error',
              matConfig: {
                duration: 0,
              },
            });

            this.snackbarRef.afterDismissed().subscribe(() => {
              this.snackbarRef = undefined;
              if (this.successMsg.length) {
                this.reweighAppNotificationService.open({
                  message: 'The process completed successfully for the following Ids:',
                  detailedMessageComponent: SnackbarDetailComponent,
                  detailedMessageComponentData: this.successMsg,
                  status: 'success',
                  matConfig: {
                    duration: 0,
                  },
                });
              }
            });
          }
          if (!this.errorMsg.length && this.successMsg.length) {
            this.reweighAppNotificationService.open({
              message: 'The process completed successfully for the following Ids:',
              detailedMessageComponent: SnackbarDetailComponent,
              detailedMessageComponentData: this.successMsg,
              status: 'success',
              matConfig: {
                duration: 0,
              },
            });
          }
        })
      )
      .subscribe((data: any) => {
        if (data?.error) {
          const message = ErrorHelper.getErrorMessageFromAny(data.error);
          this.errorMsg.push(message);
        } else {
          this.successMsg.push('LogHeader Id: ' + data[0].logHeaderId + ' update successful');
        }
      });
  }

  private createReweighCertificates(logHeaders: LogHeader[]) {
    return from(logHeaders).pipe(
      finalize(() => this.setActionInProgress(false, ActionsEnum.ACTION_ENDED)),
      mergeMap((logHeader) => {
        const rqst = new CreateReweighCertificatesRqst();
        rqst.logHeaderIds = [logHeader.logHeaderId];
        return this.reweighLogHeadersService.createReweighCertificates(rqst).pipe(timeout(60000));
      })
    );
  }

  private updateReweighLogHeader(logHeaders: LogHeader[], rqst: UpdateReweighLogHeaderRqst): void {
    const updateInfo: LogHeaderUpdateInfo = {
      errors: [],
      updated: [],
    };
    const logHeaderUpdateRqsts: Observable<LogHeader>[] = logHeaders.map((logHeader) => {
      return this.reweighLogHeadersService.updateReweighLogHeader(logHeader.logHeaderId, rqst).pipe(
        timeout(60000), // wait 1 minute
        tap((response) => {
          updateInfo.updated.push(response);
        }),
        catchError((error) => {
          const updateError: LogHeaderUpdateError = {
            logHeader,
          };

          if (error instanceof TimeoutError) {
            updateError.error = 'Timeout limit exceeded';
            updateError.canRetry = true;
          } else {
            updateError.error = ErrorHelper.getErrorMessageFromAny(error.error);
            updateError.canRetry = false;
          }

          updateInfo.errors.push(updateError);

          return of(error);
        })
      );
    });

    forkJoin(logHeaderUpdateRqsts).subscribe(
      () => {
        this.setActionInProgress(false, ActionsEnum.ACTION_ENDED);
        if (updateInfo.errors.length === 0) {
          this.reweighAppNotificationService.success('All updates successful');
        } else {
          this.showLogHeaderUpdateDialog(updateInfo, rqst);
        }
      },
      () => {
        this.setActionInProgress(false, ActionsEnum.ACTION_ENDED_WITH_ERRORS);
      }
    );
  }

  private showLogHeaderUpdateDialog(updateInfo: LogHeaderUpdateInfo, rqst: UpdateReweighLogHeaderRqst): void {
    this.dialog
      .open(FailedLogHeaderUpdateDialogComponent, {
        width: '800px',
        data: updateInfo,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((retryUpdate) => {
        if (retryUpdate) {
          const failedUpdateRecords = updateInfo.errors
            .filter((logHeaderUpdateError) => logHeaderUpdateError.canRetry)
            .map((logHeaderUpdateError) => logHeaderUpdateError.logHeader);
          this.updateReweighLogHeader(failedUpdateRecords, rqst);
        }
      });
  }

  private validateCurrentUserHasWriteAccess(methodName: string) {
    if (!this.userRoleService.currentUserHasWriteAccess()) {
      const msg: string = methodName + '-> you dont have access to that, but only read access';
      this.reweighAppNotificationService.error(msg);
      console.error('Error:', msg);
    }
  }
}
