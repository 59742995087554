import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ShipmentDimensionsFilterType } from '@shared/enums/shipment-dimensions-filter-type.enum';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import { ReweighService } from '@shared/services/reweigh-service/reweigh.service';
import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { ShipmentDetailsService } from '@shared/services/shipment-details/shipment-details.service';
import { UserRoleService } from '@shared/services/user-role';
import { ReweighLogSummaryBase } from '../reweigh-log-common/lib/reweigh-log-summary-base';
import { DmsApiWrapper } from './services/dms-api-wrapper.service';

@Component({
  selector: 'app-reweigh-log-summary-page',
  templateUrl: './reweigh-log-summary.component.html',
  styleUrls: ['./reweigh-log-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'reweigh-log-summary-page',
  },
})
export class ReweighLogSummaryPageComponent extends ReweighLogSummaryBase {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  hasManualCreateCerficateRole: boolean = false;

  constructor(
    protected dmsApiWrapper: DmsApiWrapper,
    protected shipmentDetailsService: ShipmentDetailsService,
    protected router: Router,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected dialog: MatDialog,
    protected reweighService: ReweighService,
    protected userRoleService: UserRoleService,
    protected reweighLogSummaryService: ReweighLogSummaryService,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    protected titleService: Title,
    protected changeDetection: ChangeDetectorRef
  ) {
    super(
      dmsApiWrapper,
      shipmentDetailsService,
      router,
      reweighAppNotificationService,
      dialog,
      reweighService,
      userRoleService,
      reweighLogSummaryService,
      reweighApiServiceWrapper,
      titleService,
      changeDetection
    );
    this.hasManualCreateCerficateRole = this.userRoleService.hasManualCreateCerficateRole();
  }
}
