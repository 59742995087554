import { Component } from '@angular/core';
import { DateHelper } from '@shared/classes/date-helper';
import { DmsDocument } from '@shared/classes/dms-document/dms-document';
import { ExtendedLogHeader } from '@shared/classes/entities/extended-log-header';
import { ReweighCertificateUtil } from '@shared/classes/utils/reweigh-certificate-util';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { UserRoleService } from '@shared/services/user-role';
import { LogHeader } from '@xpo-ltl/sdk-reweigh';
import { ManualReweighCertificate } from '@xpo-ltl/sdk-reweigh/lib/api-reweigh';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isEmpty as _isEmpty } from 'lodash';
import { decode } from 'typescript-base64-arraybuffer';
import { DmsApiWrapper } from '../../../reweigh-log-summary/services/dms-api-wrapper.service';

@Component({
  selector: 'app-certificate-cell-renderer',
  templateUrl: './certificate-cell-renderer.component.html',
  styleUrls: ['./certificate-cell-renderer.component.scss'],
})
export class CertificateCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  rowData: LogHeader;
  imageCreatedInd: boolean;
  extendedLogHeader: ExtendedLogHeader;
  hasManualCreateCerficateRole: boolean = false;

  constructor(
    private dmsApiWrapper: DmsApiWrapper,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected userRoleService: UserRoleService
  ) {
    this.hasManualCreateCerficateRole = this.userRoleService.hasManualCreateCerficateRole();
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.imageCreatedInd = params.data.imageCreatedInd;
    this.rowData = this.params.node.data;
    this.extendedLogHeader = this.params.node.data;
  }

  onViewCertificateClicked(): void {
    this.dmsApiWrapper
      .getDmsDocument$(this.rowData.proNbr, this.rowData.auditInfo.createdTimestamp)
      .subscribe((dmsDocument: DmsDocument) => {
        if (!_isEmpty(dmsDocument)) {
          const file = new Blob([dmsDocument.getDecodedData()], {
            type: dmsDocument.getMimeType() + ';base64',
          });
          const url = window.URL.createObjectURL(file);
          window.open(url);
        } else {
          this.reweighAppNotificationService.error('No document info available');
        }
      });
  }

  onDownloadCertificateClicked(): void {
    this.dmsApiWrapper
      .getDmsDocument$(this.rowData.proNbr, this.rowData.auditInfo.createdTimestamp)
      .subscribe((dmsDocument: DmsDocument) => {
        if (!_isEmpty(dmsDocument)) {
          const file: Blob = new Blob([dmsDocument.getDecodedData()], {
            type: dmsDocument.getMimeType() + ';base64',
          });
          const link: HTMLAnchorElement = document.createElement('a');
          link.href = window.URL.createObjectURL(file);
          link.download = dmsDocument.getFileName();
          link.click();
        } else {
          this.reweighAppNotificationService.error('No document info available');
        }
      });
  }

  refresh(): boolean {
    return false;
  }

  onDownloadManualCertificateClicked() {
    this.reweighApiServiceWrapper
      .getManualCertificate(this.extendedLogHeader?.proNbr)
      .subscribe((aManualReweighCertificate: ManualReweighCertificate) => {
        ReweighCertificateUtil.downloadCertificate(aManualReweighCertificate, this.reweighAppNotificationService);
      });
  }
}
