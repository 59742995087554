export class MatSelectOption {
  constructor(aLabel: string, aValue: string, aTooltip?: string) {
    this.label = aLabel;
    this.value = aValue;
    this.tooltip = aTooltip;
  }

  label: string;
  value: string;
  tooltip: string;
}
