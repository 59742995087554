import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserRoleService } from '@shared/services/user-role';
import { XpoButtonModule, XpoCardModule, XpoExpansionPanelModule } from '@xpo-ltl/ngx-ltl-core';
import { AppSettingsDialogComponent } from './app-settings-dialog-component/app-settings-dialog.component';

@NgModule({
  declarations: [AppSettingsDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    XpoCardModule,
    XpoExpansionPanelModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    XpoButtonModule,
  ],
  exports: [AppSettingsDialogComponent],
  providers: [UserRoleService],
})
export class RootModule {}
