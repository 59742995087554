export enum LogHeaderSummaryTitles {
  REWEIGH_SUMMARY = 'Reweigh Summary',
  PRO_NBR = 'PRO Number',
  HEADER_ID = 'Reweigh ID',
  STATUS = 'Status',
  EXEPTION_CODE = 'Exception Code',
  EXEPTION_DESC = 'Exception Description',
  SIC = 'SIC',
  OPERATOR_ID = 'Operator ID',
  OPERATOR = 'Operator',
  REWEIGH_WEIGHT = 'Reweigh Weight',
  SHIPMENT_WEIGHT = 'Shipment Weight',
  WEIGHT_CHANGE = 'Weight Change',
  EXCEEDS_TOL = 'Exceeds Tolerance',
  FINAL_DELIVERED_IND = 'Final Delivered',
  FINAL_DELIVERED_TMST = 'Delivered Timestamp',
  ERROR_DETAILS = 'Error Details',
  PIECES = 'Pieces',
  REWEIGH = 'Reweigh',
  PLT_PRO = 'PLT PRO',
  CHILD_PRO_COUNT = 'Child Pros',
  CORRECTION_COMMITTED_PIECE_COUNT = 'Pieces',
  SHIPMENT_MOTOR_MOVES = 'Motor Moves',
  SHIPMENT_LOOSE_PIECES_COUNT = 'Loose Pieces',
  CORRECTION = 'Correction',
  CORRECTION_ID = 'Correction ID',
  IMAGE_CREATED_IND_CERTIF = 'Certificate',
  REVENUE = 'Revenue',
  REVENUE_CORRECTION_AMOUNT = 'Corrected',
  REVENUE_ORIGINAL_AMOUNT = 'Original',
  REVENUE_CHANGE_AMOUNT = 'Change',
  REVENUE_PREVIEW_AMOUNT = 'Preview',
  CORRECTED_COMMODITY = 'Corrected Commodity',
  CORRECTION_COMMITTED_SEQ_NBR = 'Sequence #',
  CORRECTION_COMMITTED_CLASS = 'Class',
  CORRECTION_NMFC_ITEM = 'NMFC Item',
  CORRECTION_COMMITTED_ORIGINAL_WEIGHT = 'Original Weight',
  CORRECTION_COMMITTED_CORRECTED_WEIGHT = 'Corrected Weight',
  SHIPMENT = 'Shipment',
  SHIPMENT_PALLET_COUNT = 'Pallets',
  WEIGHT_TOLERANCE = 'Weight Tolerance',
  STANDARD_TOLERANCE_WEIGHT = 'Standard Tolerance',
  APPLIED_TOLERANCE = 'Applied Tolerance',
  APPLIED_TOLERANCE_TYPE_CD = 'Type',
  APPLIED_TOLERANCE_WEIGHT = 'Weight',
  CUSTOMER_TOLERANCE = 'Customer Tolerance',
  MAD_CODE = 'MAD Code',
  PALLET_TOLERANCE_CUSTOMER_ID = 'Customer ID',
  PARTY_TYPE = 'Party Type',
  CUSTOMER_TOLERANCE_PARENT_TYPE_CD = 'Parent Ind',
  PALLET_TOLLERANCE = 'Pallet Tolerance',
  PALLET_TOLERANCE_AGREEMENT_ID = 'Agreement ID',
  PALLET_TOLERANCE_RULESET = 'Ruleset',
  ADDITIONAL_DATA = 'Additional Data',
  SHIPMENT_INSTANCE_ID = 'Shipment Instance ID',
  RETRY_COUNT = 'Retry Count',
  DISPLAY_IND = 'Display',
  CORRELATION_ID = 'Correlation ID',
  CREATED_BY = 'Created By',
  CREATED_TMST = 'Created Timestamp',
  PROGRAM_ID = 'Program ID',
  LAST_UPDATE_BY = 'Last Update',
  UPDATED_BY = 'Updated By',
  LST_UPDT_TMST = 'Last Update Timestamp',
  MORE_INFO_CD = 'More Information Code',
  CUSTOMER_TOLERANCE_TOTAL_WEIGHT = 'Customer Tolerance Total Weight',
  SHIPMENT_PIECE_COUNT = 'Shipment pieces',
  REWEIGH_PIECE_COUNT = 'Reweigh pieces',
  PICKUP_DATE = 'Pickup Date',
  HAZMAT_IND = 'Hazmat',
  SHIPMENT_TOTAL_PIECES_COUNT = 'Total pieces',
  SHIPMENT_HAZMAT_IND = 'Hazmat',
  BILL_STATUS_CD = 'Bill Status',
  BILL_CLASS_CD = 'Bill Class',
}
