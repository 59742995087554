import { Injectable } from '@angular/core';
import { DateHelper } from '@shared/classes/date-helper';
import { ExtendedLiftScaleCalibrationHeader } from '@shared/classes/entities/extended-lift-scale-calibration-header';

import { ExtendedLogHeader } from '@shared/classes/entities/extended-log-header';
import { OperatorLogDetailHist } from '@shared/classes/entities/operator-log-detail-hist';
import { OperatorLogHeaderHist } from '@shared/classes/entities/operator-log-header-hist';
import { HighlightableLogHeader } from '@shared/classes/highlightable-log-header.interface';
import { ReweighCalibrationHeaderFilter } from '@shared/models/reweigh-calibration-header/reweigh-calibration-header-filter.model';
import { ReweighLogHeaderFilter } from '@shared/models/reweigh-log';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ApiServiceWrapper } from '@shared/services/sdk/api-service-wrapper';
import { UserRoleService } from '@shared/services/user-role';

import { InvalidProNumberError } from '@shared/classes/error/invalid-pro-number-error';
import { ProNumberUtil } from '@shared/classes/utils/pro-number-util';
import { DataOptions } from '@xpo-ltl/data-api';
import {
  CreateReweighCertificatesResp,
  CreateReweighCertificatesRqst,
  GetLogHeaderHistoryPath,
  GetLogHeaderHistoryResp,
  GetManualReweighCertificateQuery,
  GetManualReweighCertificateResp,
  GetReweighLogPath,
  GetShipmentForLogHeaderPath,
  GetShipmentForLogHeaderQuery,
  GetShipmentForLogHeaderResp,
  LiftScaleCalibrationHeader,
  ListCalibrationHeadersQuery,
  ListCalibrationHeadersResp,
  ListHistoryForLogDetailsPath,
  ListHistoryForLogDetailsResp,
  ListLatestLiftScaleTestsPath,
  ListLatestLiftScaleTestsResp,
  ListReportsForCalibrationHeadersQuery,
  ListReportsForCalibrationHeadersResp,
  ListReweighExceptionsQuery,
  ListReweighExceptionsResp,
  ListReweighLogsQuery,
  ListReweighLogsResp,
  LogHeader,
  LogHeaderShipment,
  ManualReweighCertificate,
  ReweighApiService,
  ReweighException,
  UpdateReweighLogHeaderResp,
  UpdateReweighLogHeaderRqst,
} from '@xpo-ltl/sdk-reweigh';
import { List } from 'immutable';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReweighApiServiceWrapper extends ApiServiceWrapper<ReweighApiService> {
  static readonly SCALE_FORKLIFT_REPORTS_MAX_ROWS_NUMBER: number = 20_000;
  static readonly LIST_CALIBRATION_HEADERS_ROWS_COUNT_MAX: number = 22_000;

  protected highlightedLogHeaderId: number;
  reweighExceptions: List<ReweighException> = List();
  isReweighExceptionsLoaded: boolean = false;

  constructor(
    protected baseService: ReweighApiService,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected userRoleService: UserRoleService
  ) {
    super(baseService, reweighAppNotificationService, userRoleService);
  }

  updateReweighLogHeader(logHeaderId: number, rqstParam: UpdateReweighLogHeaderRqst): Observable<LogHeader> {
    this.validateCurrentUserHasWriteAccess('createReweighCertificates');
    const path = {
      logHeaderId,
    };

    return this.baseService
      .updateReweighLogHeader(rqstParam, path, this.getSimpleDataOptions())
      .pipe(map((response: UpdateReweighLogHeaderResp) => response?.logHeader));
  }

  createReweighCertificates(rqstParams: CreateReweighCertificatesRqst) {
    this.validateCurrentUserHasWriteAccess('createReweighCertificates');
    return this.baseService.createReweighCertificates(rqstParams, this.getSimpleDataOptions()).pipe(
      map((response: CreateReweighCertificatesResp) => response?.reweighCertificates),
      catchError((response) => {
        return of({ error: response?.error });
      })
    );
  }

  getReweighLog(logHeaderId: number, loadingOverlayEnabled = true): Observable<ExtendedLogHeader> {
    if (logHeaderId && logHeaderId > 0) {
      const path: GetReweighLogPath = { logHeaderId };
      const options: DataOptions = {
        toastOnError: false,
        loadingOverlayEnabled,
      };

      return this.baseService.getReweighLog(path, options).pipe(
        map((response) => {
          const result: ExtendedLogHeader = ExtendedLogHeader.toExtendedLogHeader(response?.reweighLogHeader);
          return result;
        }),
        catchError((error) => this.handleError(error))
      );
    }
  }

  listReweighLogs(reweighFilter: ReweighLogHeaderFilter): Observable<ListReweighLogsResp> {
    const query: ListReweighLogsQuery = reweighFilter.getListReweighLogsQuery();

    return this.baseService.listReweighLogs(query, this.getSimpleDataOptions()).pipe(
      map((response: ListReweighLogsResp) => {
        if (reweighFilter.filterType === 'proSearch' && reweighFilter.logHeaderId) {
          this.highlightedLogHeaderId = +reweighFilter.logHeaderId;
          const logHeadertoHighlight: HighlightableLogHeader = response.reweighLogHeaders.find(
            (value) => value.logHeaderId === +reweighFilter.logHeaderId
          );
          logHeadertoHighlight.highlight = true;
        }
        return response;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  getHighlightedLogHeaderId(): number {
    return this.highlightedLogHeaderId;
  }

  listReweighExceptions(rqstParams: ListReweighExceptionsQuery): Observable<ListReweighExceptionsResp> {
    return this.baseService.listReweighExceptions(rqstParams, this.getSimpleDataOptions()).pipe(
      tap((response: ListReweighExceptionsResp) => {
        return response;
      }),
      catchError((error) => {
        console.error('listReweighExceptions error:', error);
        return this.handleError(error);
      })
    );
  }

  loadReweighExceptionsIfEmpty(): Observable<List<ReweighException>> {
    if (this.reweighExceptions.isEmpty()) {
      return this.listReweighExceptions(new ListReweighExceptionsQuery()).pipe(
        map((response: ListReweighExceptionsResp) => {
          this.reweighExceptions = List();
          if (response.reweighExceptions && response.reweighExceptions.length > 0) {
            this.reweighExceptions = List(response.reweighExceptions);
            this.reweighExceptions.sort((a: ReweighException, b: ReweighException) =>
              a.exceptionCd.localeCompare(b.exceptionCd)
            );
            this.isReweighExceptionsLoaded = true;
          }

          return this.reweighExceptions;
        }),
        catchError((error) => {
          console.error('loadReweighExceptionsIfEmpty error:', error);
          return this.handleError(error);
        })
      );
    } else {
      return of(this.reweighExceptions);
    }
  }

  getExceptionByExceptionCd(aExceptionCd: string): ReweighException {
    let result: ReweighException = null;
    if (this.reweighExceptions.isEmpty()) {
      throw Error('getExceptionByExceptionCd cannot get code if reweighExceptions are not loaded');
    } else {
      for (const reweighException of this.reweighExceptions) {
        if (reweighException.exceptionCd === aExceptionCd) {
          result = reweighException;
          break;
        }
      }
      return result;
    }
  }

  listCalibrationHeaders(
    calibrationHeaderFilter: ReweighCalibrationHeaderFilter
  ): Observable<ListCalibrationHeadersResp> {
    const query: ListCalibrationHeadersQuery = calibrationHeaderFilter;

    return this.baseService.listCalibrationHeaders(query, this.getSimpleDataOptions()).pipe(
      map((response: ListCalibrationHeadersResp) => {
        return response;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  listReportsForCalibrationHeaders(
    request: ListReportsForCalibrationHeadersQuery
  ): Observable<ListReportsForCalibrationHeadersResp> {
    const query: ListReportsForCalibrationHeadersQuery = request;

    return this.baseService.listReportsForCalibrationHeaders(query, this.getSimpleDataOptions()).pipe(
      map((response: ListReportsForCalibrationHeadersResp) => {
        return response;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  /**
   * returns empty array if no result found
   * @param aLogHeaderId
   */
  listHistoryForLogDetails(aLogHeaderId: number): Observable<OperatorLogDetailHist[]> {
    const pathParams: ListHistoryForLogDetailsPath = new ListHistoryForLogDetailsPath();
    pathParams.logHeaderId = aLogHeaderId;
    return this.baseService.listHistoryForLogDetails(pathParams, this.getSimpleDataOptions()).pipe(
      map((response: ListHistoryForLogDetailsResp) => {
        const result: OperatorLogDetailHist[] = [];
        if (response.logDetailHist && response.logDetailHist.length > 0) {
          for (const logDetailHist of response.logDetailHist) {
            result.push(OperatorLogDetailHist.toOperatorLogDetailHist(logDetailHist));
          }
        } else {
          console.warn('listHistoryForLogDetails-> response history is undefined');
        }
        return result;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  listHistoryForLogHeader(aLogHeaderId: number): Observable<OperatorLogHeaderHist[]> {
    if (isNaN(+aLogHeaderId) || aLogHeaderId <= 0) {
      const msg: string = 'given parameter aLogHeaderId is not a number or <=0';
      console.error(msg + ':', aLogHeaderId);
      throw Error(msg);
    } else {
      const pathParams: GetLogHeaderHistoryPath = new GetLogHeaderHistoryPath();
      pathParams.logHeaderId = aLogHeaderId;
      // getLogHeaderHistory(pathParams: GetLogHeaderHistoryPath,
      // dataOptions?: DataOptions, httpOptions?: HttpOptions): Observable<GetLogHeaderHistoryResp>;
      return this.baseService.getLogHeaderHistory(pathParams, this.getSimpleDataOptions()).pipe(
        map((response: GetLogHeaderHistoryResp) => {
          const result: OperatorLogHeaderHist[] = [];
          if (response.logHeaderHists && response.logHeaderHists.length > 0) {
            for (const logHeaderHist of response.logHeaderHists) {
              result.push(OperatorLogHeaderHist.toOperatorLogHeaderHist(logHeaderHist));
            }
          } else {
            console.warn('listHistoryForLogHeader-> response history is undefined');
          }
          return result;
        }),
        catchError((error) => this.handleError(error))
      );
    }
  }

  /**
   * https://xpo.atlassian.net/browse/LEI-1743
   *
   * @param aLogHeaderId
   */
  listLatestScaleTestsByLogHeaderId(aLogHeaderId: number): Observable<List<ExtendedLiftScaleCalibrationHeader>> {
    if (isNaN(+aLogHeaderId) || aLogHeaderId <= 0) {
      const msg: string = 'given parameter aLogHeaderId is not a number or <=0';
      console.error(msg + ':', aLogHeaderId);
      throw Error(msg);
    } else {
      const pathParams: ListLatestLiftScaleTestsPath = new ListLatestLiftScaleTestsPath();
      pathParams.logHeaderId = aLogHeaderId;
      return this.baseService.listLatestLiftScaleTests(pathParams, this.getSimpleDataOptions()).pipe(
        map((response: ListLatestLiftScaleTestsResp) => {
          const result: ExtendedLiftScaleCalibrationHeader[] = [];
          if (response.liftScaleTests?.length > 0) {
            for (let i = 0; i < response.liftScaleTests.length; i++) {
              const liftScaleCalibrationHeader: LiftScaleCalibrationHeader = response.liftScaleTests[i];
              result.push(
                ExtendedLiftScaleCalibrationHeader.toExtendedLiftScaleCalibrationHeader(liftScaleCalibrationHeader)
              );
            }
          }
          return List(result);
        }),
        catchError((error) => this.handleError(error))
      );
    }
  }

  /**
   * https://xpo.atlassian.net/browse/LEI-1743
   *
   * @param aLogHeaderId
   */
  getShipmentForLogHeaderByLogHeaderId(aLogHeaderId: number): Observable<LogHeaderShipment> {
    if (isNaN(+aLogHeaderId) || aLogHeaderId <= 0) {
      const msg: string = 'given parameter aLogHeaderId is not a number or <=0';
      console.error(msg + ':', aLogHeaderId);
      throw Error(msg);
    } else {
      const pathParams: GetShipmentForLogHeaderPath = new GetShipmentForLogHeaderPath();
      pathParams.logHeaderId = aLogHeaderId;
      const getShipmentForLogHeaderQuery: GetShipmentForLogHeaderQuery = new GetShipmentForLogHeaderQuery();
      return this.baseService
        .getShipmentForLogHeader(pathParams, getShipmentForLogHeaderQuery, this.getSimpleDataOptions())
        .pipe(
          map((response: GetShipmentForLogHeaderResp) => {
            let result: LogHeaderShipment = new LogHeaderShipment();
            if (response.logHeaderShipment) {
              result = response.logHeaderShipment;
              result.pickupDate = DateHelper.fromApiDateStringToFormattedDateString(
                response.logHeaderShipment?.pickupDate
              );
            }
            return result;
          }),
          catchError((error) => this.handleError(error))
        );
    }
  }

  getManualCertificate(aProNbrTxt: string | undefined): Observable<ManualReweighCertificate> {
    if (!ProNumberUtil.isValidAndNotNull(aProNbrTxt)) {
      throw new InvalidProNumberError('given proNumber is invalid:' + aProNbrTxt);
    } else {
      const getManualReweighCertificateQuery: GetManualReweighCertificateQuery = new GetManualReweighCertificateQuery();
      getManualReweighCertificateQuery.proNbr = aProNbrTxt;
      return this.baseService
        .getManualReweighCertificate(getManualReweighCertificateQuery, this.getSimpleDataOptions())
        .pipe(
          map((response: GetManualReweighCertificateResp) => {
            let result: ManualReweighCertificate = new ManualReweighCertificate();
            if (response.manualReweighCertificate) {
              result = response.manualReweighCertificate;
            }
            return result;
          }),
          catchError((error) => this.handleError(error))
        );
    }
  }
}
