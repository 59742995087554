import { IllegalArgumentError } from '@shared/classes/error/illegal-argument-error';
import { IllegalStateError } from '@shared/classes/error/illegal-state-error';
import { InvalidProNumberError } from '@shared/classes/error/invalid-pro-number-error';

/**
 * Util only for logic, see ProNumber object class and proNumberHelper for UI/components related stuff
 *
 * Note that blue is parent, yellow is child
 */
export class ProNumberUtil {
  static readonly HYPHEN: string = '-';

  protected static readonly PRO_REGEXES = {
    NINE_DIGIT: /^[0-9]{9}$/,
    ELEVEN_DIGIT: /^[0-9]{11}$/,
    NINE_DIGIT_HYPHEN_BLUE_PRO: /^[0-9]{3}-[0-9]{6}$/,
    TEN_DIGIT_HYPHEN_BLUE_PRO: /^0[0-9]{3}-[0-9]{6}$/,
    ELEVEN_DIGIT_BLUE_PRO: /^0[0-9]{3}0[0-9]{6}$/,
    TEN_DIGIT_YELLOW_PRO: /^[0-9]{3}[1-9][0-9]{6}$/,
    ELEVEN_DIGIT_YELLOW_PRO: /^0[0-9]{3}[1-9][0-9]{6}$/,
    TEN_DIGIT_HYPHEN_YELLOW_PRO: /^[0-9]{3}[1-9]-[0-9]{6}$/,
  };

  /**
   * goes through regexes
   *
   * @param aProNbrTxt
   */
  static isValidAndNotNull(aProNbrTxt: string): boolean {
    if (aProNbrTxt?.length > 0) {
      return (
        ProNumberUtil.isValidParentProNbrTxtAndNotNull(aProNbrTxt) ||
        ProNumberUtil.isValidChildProNbrTxtAndNotNull(aProNbrTxt)
      );
    } else {
      return false;
    }
  }

  /**
   * adapted from ltl-java-common-utils, goes through regexes
   *
   * @param aProNbrTxt
   */
  static getElevenDigit(aProNbrTxt: string): string {
    ProNumberUtil.validateProNbrTxtAndNotNull(aProNbrTxt);
    let result: string;
    if (ProNumberUtil.PRO_REGEXES.ELEVEN_DIGIT.test(aProNbrTxt)) {
      result = aProNbrTxt;
    } else if (ProNumberUtil.PRO_REGEXES.NINE_DIGIT.test(aProNbrTxt)) {
      // [0-9]{3}-[0-9]{6} ex: 000000000
      const lPrefix: string = aProNbrTxt.substr(0, 3);
      const lSuffix: string = aProNbrTxt.substr(3, 6);
      result = `0${lPrefix}0${lSuffix}`;
    } else if (ProNumberUtil.PRO_REGEXES.NINE_DIGIT_HYPHEN_BLUE_PRO.test(aProNbrTxt)) {
      // [0-9]{3}-[0-9]{6} ex: 000-000000
      const lPrefix: string = aProNbrTxt.substr(0, 3);
      const lSuffix: string = aProNbrTxt.substr(4, 6);
      result = `0${lPrefix}0${lSuffix}`;
    } else if (ProNumberUtil.PRO_REGEXES.TEN_DIGIT_HYPHEN_BLUE_PRO.test(aProNbrTxt)) {
      // 0[0-9]{3}-[0-9]{6} ex: 0000-000000|0391-609606->03910609606
      const lPrefix: string = aProNbrTxt.substr(1, 3);
      const lSuffix: string = aProNbrTxt.substr(5, 6);
      result = `0${lPrefix}0${lSuffix}`;
    } else if (ProNumberUtil.PRO_REGEXES.TEN_DIGIT_YELLOW_PRO.test(aProNbrTxt)) {
      // [0-9]{3}[1-9]{1}[0-9]{6} ex: 0001000000
      result = `0${aProNbrTxt}`;
    } else if (ProNumberUtil.PRO_REGEXES.TEN_DIGIT_HYPHEN_YELLOW_PRO.test(aProNbrTxt)) {
      // [0-9]{3}[1-9]-[0-9]{6} ex: 0001-000000
      result = `0${aProNbrTxt.replace('-', '')}`;
    } else {
      throw new IllegalStateError('untreated case:' + aProNbrTxt);
    }

    if (result.length !== 11 || result.indexOf(ProNumberUtil.HYPHEN) > 0) {
      throw new IllegalStateError(
        'result is not 11 digits but:' +
          result.length +
          ' src:' +
          aProNbrTxt +
          ' result:' +
          result +
          ' complying regex:' +
          ProNumberUtil.getComplyingRegexes(aProNbrTxt)
      );
    }

    return result;
  }

  static isValidParentProNbrTxtAndNotNull(aProNbrTxt: string): boolean {
    return ProNumberUtil.isBlue(aProNbrTxt);
  }

  static validateProNbrTxtAndNotNull(aProNbrTxt: string | undefined) {
    if (!ProNumberUtil.isValidAndNotNull(aProNbrTxt)) {
      throw new InvalidProNumberError('given pro is invalid:' + aProNbrTxt);
    }
  }

  static getHyphenedProNbrTxt(aProNbrTxt: string): string {
    let result: string;
    if (ProNumberUtil.PRO_REGEXES.NINE_DIGIT_HYPHEN_BLUE_PRO.test(aProNbrTxt)) {
      // [0-9]{3}-[0-9]{6}
      result = aProNbrTxt;
    } else {
      if (ProNumberUtil.PRO_REGEXES.TEN_DIGIT_HYPHEN_BLUE_PRO.test(aProNbrTxt)) {
        // 0[0-9]{3}-[0-9]{6}
        const lPrefix: string = aProNbrTxt.substr(1, 3);
        const lSuffix: string = aProNbrTxt.substr(5, 6);
        result = `${lPrefix}${ProNumberUtil.HYPHEN}${lSuffix}`;
      } else if (ProNumberUtil.PRO_REGEXES.NINE_DIGIT.test(aProNbrTxt)) {
        // 000000000
        const lPrefix: string = aProNbrTxt.substr(0, 3);
        const lSuffix: string = aProNbrTxt.substr(3, 6);
        result = `${lPrefix}${ProNumberUtil.HYPHEN}${lSuffix}`;
      } else if (ProNumberUtil.PRO_REGEXES.TEN_DIGIT_HYPHEN_YELLOW_PRO.test(aProNbrTxt)) {
        // [0-9]{3}[1-9]-[0-9]{6} ex: 0001-000000|1561-594311->1561-594311R
        if (aProNbrTxt.startsWith('0')) {
          const lPrefix: string = aProNbrTxt.substr(0, 4);
          const lSuffix: string = aProNbrTxt.substr(5, 6);
          result = `${lPrefix}${ProNumberUtil.HYPHEN}${lSuffix}`;
        } else {
          result = aProNbrTxt;
        }
      } else if (ProNumberUtil.PRO_REGEXES.ELEVEN_DIGIT_BLUE_PRO.test(aProNbrTxt)) {
        // 0[0-9]{3}0[0-9]{6} ex: 00000000000|03740679082->374-679082
        const lPrefix: string = aProNbrTxt.substr(1, 3);
        const lSuffix: string = aProNbrTxt.substr(5, 6);
        result = `${lPrefix}${ProNumberUtil.HYPHEN}${lSuffix}`;
      } else if (ProNumberUtil.PRO_REGEXES.TEN_DIGIT_YELLOW_PRO.test(aProNbrTxt)) {
        // [0-9]{3}[1-9][0-9]{6} ex: 0001000000|3740679082 not taken in consideration|1561594311->1561-594311
        const lPrefix: string = aProNbrTxt.substr(0, 4);
        const lSuffix: string = aProNbrTxt.substr(4, 6);
        result = `${lPrefix}${ProNumberUtil.HYPHEN}${lSuffix}`;
      } else if (ProNumberUtil.PRO_REGEXES.ELEVEN_DIGIT_YELLOW_PRO.test(aProNbrTxt)) {
        // 0[0-9]{3}[1-9][0-9]{6} ex: 00001000000|06481938831->6481-938831
        const lPrefix: string = aProNbrTxt.substr(1, 4);
        const lSuffix: string = aProNbrTxt.substr(5, 6);
        result = `${lPrefix}${ProNumberUtil.HYPHEN}${lSuffix}`;
      } else {
        // tslint:disable-next-line:max-line-length
        // see https://github.xpo.com/OneXPO/ltl-java-common-util/blob/09e53eaa501214d5f7cde137b23986d9ec05c89b/ltl/contract/src/main/java/com/xpo/common/ltl/contract/internal/helper/ProNumberHelperWriter.kt for source
        throw new IllegalStateError('not treated case for pro:' + aProNbrTxt);
      }
    }

    // ensure
    if (result.indexOf(ProNumberUtil.HYPHEN) < 0) {
      throw new IllegalStateError('result doesnt contain a hyphen:' + result);
    } else {
      // can be blue and yellow... first check for blue
      const isBlue: boolean = ProNumberUtil.isBlue(aProNbrTxt);
      if (isBlue && result.length !== 10) {
        throw new IllegalStateError(
          'result blue pro should be of length 10 but is ' +
            result.length +
            ' result:' +
            result +
            ' src:' +
            aProNbrTxt +
            ' complying regex:' +
            ProNumberUtil.getComplyingRegexes(aProNbrTxt)
        );
      } else if (!isBlue && ProNumberUtil.isYellow(aProNbrTxt) && result.length !== 11) {
        throw new IllegalStateError(
          'result yellow pro should be of length 11 but is ' +
            result.length +
            ' result:' +
            result +
            ' src:' +
            aProNbrTxt +
            ' complying regex:' +
            ProNumberUtil.getComplyingRegexes(aProNbrTxt)
        );
      }
    }

    return result;
  }

  static isBlue(aProNbrTxt: string): boolean {
    const result: boolean =
      ProNumberUtil.PRO_REGEXES.NINE_DIGIT.test(aProNbrTxt) ||
      ProNumberUtil.PRO_REGEXES.ELEVEN_DIGIT_BLUE_PRO.test(aProNbrTxt) ||
      ProNumberUtil.PRO_REGEXES.NINE_DIGIT_HYPHEN_BLUE_PRO.test(aProNbrTxt) ||
      ProNumberUtil.PRO_REGEXES.TEN_DIGIT_HYPHEN_BLUE_PRO.test(aProNbrTxt);

    return result;
  }

  static isYellow(aProNbrTxt: string): boolean {
    // we first have to test if is blue, if not it can be yellow
    const result: boolean =
      !ProNumberUtil.isBlue(aProNbrTxt) &&
      (ProNumberUtil.PRO_REGEXES.ELEVEN_DIGIT_YELLOW_PRO.test(aProNbrTxt) ||
        ProNumberUtil.PRO_REGEXES.TEN_DIGIT_YELLOW_PRO.test(aProNbrTxt) ||
        ProNumberUtil.PRO_REGEXES.TEN_DIGIT_HYPHEN_YELLOW_PRO.test(aProNbrTxt));

    return result;
  }

  /**
   * only for Blue/parent pros
   *
   * @param aProNbrTxt
   */
  static get9Digit(aProNbrTxt: string): string {
    let result: string;
    if (ProNumberUtil.isValidParentProNbrTxtAndNotNull(aProNbrTxt)) {
      // from ltl-common-utils
      const lDashedParentProTxt: string = ProNumberUtil.getHyphenedProNbrTxt(aProNbrTxt);
      result = lDashedParentProTxt.replace('-', '');
    } else {
      throw new IllegalArgumentError(
        'given pro is not a parentPro, nine digit version can only be requested for parent pros:' + aProNbrTxt
      );
    }

    if (result.length !== 9) {
      throw new IllegalStateError('result should be 9 digits but is ' + result.length + ' result:' + result);
    }
    return result;
  }

  static isValidChildProNbrTxtAndNotNull(aChildProNbrTxt: string): boolean {
    return ProNumberUtil.isYellow(aChildProNbrTxt);
  }

  /**
   * For tests
   * @param aProNbrTxt
   */
  static getComplyingRegexes(aProNbrTxt: string): string {
    let result: string = '';
    for (const [key, regex] of Object.entries(ProNumberUtil.PRO_REGEXES)) {
      // const regex: RegExp = Object.entries(ProNumberUtil.PRO_REGEXES)[key];
      if (regex?.test(aProNbrTxt)) {
        result += key + ':' + regex + '|';
      }
    }
    return result;
  }
}
