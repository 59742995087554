import { Clipboard } from '@angular/cdk/clipboard';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateHelper } from '@shared/classes/date-helper';
import { IllegalArgumentError } from '@shared/classes/error/illegal-argument-error';
import { MatTableExporterOutputFileType } from '@shared/enums/mat-table-exporter-output-file.type';
import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { LogHeader, LogHeaderHist, ReweighException } from '@xpo-ltl/sdk-reweigh';
import { List } from 'immutable';

@Component({
  selector: 'app-rw-exception-cd-dialog',
  templateUrl: './rw-exception-cd-dialog.component.html',
  styleUrls: [
    './rw-exception-cd-dialog.component.scss',
    '../../reweigh-log-headers/reweigh-log-headers-home/cell-renderers/rw-yes-no-renderer-component/rw-yes-no-renderer.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RwExceptionCdDialogComponent implements AfterViewInit {
  readonly MatTableExporterOutputFileType = MatTableExporterOutputFileType;

  @ViewChild(MatSort, { static: true }) dataSourceSort: MatSort;

  isDatasourceSetted: boolean = false;

  logHeader: LogHeader;
  logHeaderHist: LogHeaderHist;

  currentReweighException: ReweighException;
  datasource: MatTableDataSource<ReweighException>;
  selection: SelectionModel<ReweighException> = new SelectionModel<ReweighException>(false, []);
  protected renderedData: any; // for clipboard from mat-table

  visibleColumns: string[] = [
    'exceptionCd',
    'exceptionMessage',
    'exceptionStatus',
    'promptPermittedInd',
    'resubmittableInd',
    'retryCount',
    'expectedInd',
    'bigQueryCategory',
    'detailsInHeaderInd',
    'className',
    'processedCorrectionInd',
    'manualPdfCreationInd',
    'autoPdfCreationInd',
  ];
  private reweighExceptions: List<ReweighException> = List();

  constructor(
    public dialogRef: MatDialogRef<RwExceptionCdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reweighService: ReweighApiServiceWrapper,
    protected changeDetectorRefs: ChangeDetectorRef,
    private clipboard: Clipboard
  ) {
    this.logHeader = data.logHeader;
    this.logHeaderHist = data.logHeaderHist;
    if (reweighService.isReweighExceptionsLoaded) {
      this.datasource = new MatTableDataSource(reweighService.reweighExceptions.toArray());
      this.isDatasourceSetted = true;
    } else {
      this.datasource = new MatTableDataSource();
    }
  }

  static getDialogConfig(logHeader: LogHeader | undefined, logHeaderHist: LogHeaderHist | undefined) {
    let lId: number;
    if (logHeader) {
      lId = logHeader.logHeaderId;
    } else if (logHeaderHist) {
      lId = logHeaderHist.logHeaderId;
    }
    return {
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: '90%',
      width: '90%',
      data: {
        title: `Exception Code for Log Header ID ${lId}`,
        logHeader: logHeader,
        logHeaderHist: logHeaderHist,
      },
    };
  }

  private _setCurrentReweighException() {
    if (this.logHeader && this.logHeader.exceptionCd) {
      this.currentReweighException = this.reweighService.getExceptionByExceptionCd(this.logHeader.exceptionCd);
    } else if (this.logHeaderHist && this.logHeaderHist.exceptionCd) {
      this.currentReweighException = this.reweighService.getExceptionByExceptionCd(this.logHeaderHist.exceptionCd);
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.datasource.sort = this.dataSourceSort;
    if (!this.isDatasourceSetted) {
      this.reweighService.loadReweighExceptionsIfEmpty().subscribe((aReweighExceptions: List<ReweighException>) => {
        this.isDatasourceSetted = true;
        this.datasource.data = aReweighExceptions.toArray();
        this.reweighExceptions = aReweighExceptions;
        this._setCurrentReweighException();
      });
    } else {
      this._setCurrentReweighException();
    }
    this.datasource.connect().subscribe((d) => (this.renderedData = d));
  }

  copyCsvToClipboard(aExporter, aMatTableExporterOutputFileType: MatTableExporterOutputFileType) {
    if (aMatTableExporterOutputFileType) {
      const lFileName: string =
        DateHelper.getPortandTzFormattedDate(new Date(), DateHelper.DEFAULT_YYYYMMDD_HHMMSS) +
        '_reweigh_exceptions_list';
      if (aMatTableExporterOutputFileType === MatTableExporterOutputFileType.XLSX) {
        aExporter.exportTable('xlsx', {
          fileName: lFileName,
          sheet: 'Reweigh Exceptions List',
          Props: { Author: 'Reweigh' },
        });
      } else if (aMatTableExporterOutputFileType === MatTableExporterOutputFileType.CSV) {
        aExporter.exportTable('csv', {
          fileName: lFileName,
        });
      } else {
        throw new IllegalArgumentError('invalid export file type');
      }
    }
  }
}
