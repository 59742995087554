import { ExtendedLogHeader } from '@shared/classes/entities/extended-log-header';
import { LogHeaderGroup } from '@shared/classes/log-header-summary-group/log-header-group';
import { ProNumberHelper } from '@shared/classes/pro-number-helper/pro-number-helper';
import { MiscUtil } from '@shared/classes/utils/misc-util';
import { ReweighLogDateFormats } from '@shared/enums/reweigh-log/date-formats.enum';
import { LogHeaderSummaryTitles } from '@shared/enums/reweigh-log/reweigh-log-header-titles';
import { LogHeaderField } from '@shared/interfaces/log-header-field.interface';
import { YesNoOptionPipe } from '@shared/pipes';
import { FormatNumberOrNaPipe } from '@shared/pipes/format-number-or-na.pipe';
import { YesNoOptionPipeOrNa } from '@shared/pipes/yes-no-option/yes-no-option-or-na.pipe';
import { UserRoleService } from '@shared/services/user-role';
import { LogHeaderShipment } from '@xpo-ltl/sdk-reweigh';
import { get as _get } from 'lodash';
import { DateHelper } from '../date-helper';

export class LogHeaderSummaryGroups {
  static readonly DEFAULT_NULL_VALUE: string = 'N/A';
  groups: LogHeaderGroup[];
  reweighSummaryGroup: LogHeaderGroup;
  shipmentGroup: LogHeaderGroup;

  readonly dateFormat = ReweighLogDateFormats.MOMENT;

  constructor(
    protected userRoleService: UserRoleService,
    logHeader: ExtendedLogHeader,
    logHeaderShipment: LogHeaderShipment
  ) {
    this.groups = [];
    if (logHeader) {
      this.reweighSummaryGroup = this.getSummaryGroup(logHeader);
      this.groups.push(this.reweighSummaryGroup);
    }
    if (logHeaderShipment) {
      this.shipmentGroup = this.getShipmentGroup(logHeaderShipment);
      this.groups.push(this.shipmentGroup);
    }
    if (logHeader) {
      this.groups.push(this.getPiecesGroup(logHeader));
      this.groups.push(this.getCorrectionGroup(logHeader));
      if (this.userRoleService.currentUserHasWriteAccess()) {
        this.groups.push(this.getWeighToleranceGroup(logHeader));
        this.groups.push(this.getAdditionalDataGroup(logHeader));
      }
    }
  }

  getSummaryGroup(logHeader: ExtendedLogHeader): LogHeaderGroup {
    const lProNumberField: LogHeaderField = {
      title: LogHeaderSummaryTitles.PRO_NBR,
      value: ProNumberHelper.getStandardizedProNbr(logHeader.proNbr),
    };
    if (this.userRoleService.currentUserHasWriteAccess()) {
      lProNumberField.isLink = true;
      lProNumberField.linkToolTipText = 'Search pro number';
    }

    const result: LogHeaderGroup = this.createGroup(
      LogHeaderSummaryTitles.REWEIGH_SUMMARY,
      // first line
      lProNumberField,
      { title: LogHeaderSummaryTitles.HEADER_ID, value: logHeader.logHeaderId },
      {
        title: LogHeaderSummaryTitles.STATUS,
        value: logHeader.statusCd ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
      },
      this.getExceptionCode(logHeader),
      {
        title: LogHeaderSummaryTitles.EXEPTION_DESC,
        value: logHeader.exceptionDescription ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
      },
      // second line
      {
        title: LogHeaderSummaryTitles.SHIPMENT_WEIGHT,
        value: FormatNumberOrNaPipe.getFormattedNumber(logHeader?.shipmentWeight),
      },
      {
        title: LogHeaderSummaryTitles.REWEIGH_WEIGHT,
        value: FormatNumberOrNaPipe.getFormattedNumber(logHeader?.reweighWeight),
      },
      {
        title: LogHeaderSummaryTitles.WEIGHT_CHANGE,
        value: FormatNumberOrNaPipe.getFormattedNumber(logHeader?.weightChange),
      },
      {
        title: LogHeaderSummaryTitles.EXCEEDS_TOL,
        value: YesNoOptionPipeOrNa.formatOrNa(logHeader.exceedsToleranceInd),
      },
      {
        title: LogHeaderSummaryTitles.ERROR_DETAILS,
        isLink: logHeader.hasErrorDetail,
        linkToolTipText: 'Click to see error details',
        value: logHeader.hasErrorDetail ? 'See error details' : LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        isNotVisibleForReadonlyView: true,
      },
      // third line
      {
        title: LogHeaderSummaryTitles.SIC,
        value: logHeader.sicCode ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
      },
      {
        title: LogHeaderSummaryTitles.OPERATOR,
        value: logHeader.operatorFullName ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        isNotVisibleForReadonlyView: true,
      },
      {
        title: LogHeaderSummaryTitles.OPERATOR_ID,
        value: logHeader.operatorId ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        isNotVisibleForReadonlyView: true,
      },
      {
        title: LogHeaderSummaryTitles.HAZMAT_IND,
        value: YesNoOptionPipeOrNa.formatOrNa(logHeader.hazmatInd),
      }
    );
    result.moreInformationTooltip = 'Reweigh and shipment data at the time of Reweigh processing';

    return result;
  }

  getPiecesGroup(logHeader: ExtendedLogHeader): LogHeaderGroup {
    return this.createGroup(
      LogHeaderSummaryTitles.PIECES,
      this.createGroup(
        LogHeaderSummaryTitles.SHIPMENT,
        {
          title: LogHeaderSummaryTitles.SHIPMENT_TOTAL_PIECES_COUNT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader.shipmentPieceCount),
        },
        {
          title: LogHeaderSummaryTitles.SHIPMENT_PALLET_COUNT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader.shipmentPalletCount),
        },
        {
          title: LogHeaderSummaryTitles.SHIPMENT_LOOSE_PIECES_COUNT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader.shipmentLoosePieceCount),
        },
        {
          title: LogHeaderSummaryTitles.SHIPMENT_MOTOR_MOVES,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader.shipmentMotorPieceCount),
        }
      ),
      this.createGroup(
        LogHeaderSummaryTitles.REWEIGH,
        { title: LogHeaderSummaryTitles.PLT_PRO, value: YesNoOptionPipeOrNa.formatOrNa(logHeader.pltProInd) },
        {
          title: LogHeaderSummaryTitles.CHILD_PRO_COUNT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader.childProCount),
        },
        {
          title: LogHeaderSummaryTitles.CORRECTION_COMMITTED_PIECE_COUNT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader.reweighPieceCount),
        }
      )
    );
  }

  getCorrectionGroup(logHeader: ExtendedLogHeader): LogHeaderGroup {
    return this.createGroup(
      LogHeaderSummaryTitles.CORRECTION,
      {
        title: LogHeaderSummaryTitles.CORRECTION_ID,
        value: logHeader.correctionId ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
      },
      {
        title: LogHeaderSummaryTitles.IMAGE_CREATED_IND_CERTIF,
        value: YesNoOptionPipeOrNa.formatOrNa(logHeader.imageCreatedInd),
        hasCustomTemplate: true,
      },
      this.createGroup(
        LogHeaderSummaryTitles.REVENUE,
        {
          title: LogHeaderSummaryTitles.REVENUE_CORRECTION_AMOUNT,
          value: this.getFormattedCurrency(logHeader?.revenueCorrAmount),
        },
        {
          title: LogHeaderSummaryTitles.REVENUE_ORIGINAL_AMOUNT,
          value: this.getFormattedCurrency(logHeader?.revenueOriginAmount),
        },
        {
          title: LogHeaderSummaryTitles.REVENUE_CHANGE_AMOUNT,
          value: this.getFormattedCurrency(logHeader?.revenueChangeAmount),
        },
        {
          title: LogHeaderSummaryTitles.REVENUE_PREVIEW_AMOUNT,
          value: this.getFormattedCurrency(logHeader?.revenuePreviewAmount),
        }
      ),
      this.createGroup(
        LogHeaderSummaryTitles.CORRECTED_COMMODITY,
        {
          title: LogHeaderSummaryTitles.CORRECTION_COMMITTED_SEQ_NBR,
          value: logHeader.corrCommSequenceNbr,
        },
        {
          title: LogHeaderSummaryTitles.CORRECTION_COMMITTED_CLASS,
          value: logHeader.corrCommClass ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        },
        {
          title: LogHeaderSummaryTitles.CORRECTION_NMFC_ITEM,
          value: logHeader.corrCommNmfcItem ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        },
        {
          title: LogHeaderSummaryTitles.CORRECTION_COMMITTED_PIECE_COUNT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader.corrCommPieceCount),
        },
        {
          title: '',
          value: '',
        },
        {
          title: LogHeaderSummaryTitles.CORRECTION_COMMITTED_ORIGINAL_WEIGHT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader?.corrCommOriginWeight),
        },
        {
          title: LogHeaderSummaryTitles.CORRECTION_COMMITTED_CORRECTED_WEIGHT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader?.corrCommNewWeight),
        }
      )
    );
  }

  getWeighToleranceGroup(logHeader: ExtendedLogHeader): LogHeaderGroup {
    return this.createGroup(
      LogHeaderSummaryTitles.WEIGHT_TOLERANCE,
      {
        title: LogHeaderSummaryTitles.STANDARD_TOLERANCE_WEIGHT,
        value: FormatNumberOrNaPipe.getFormattedNumber(logHeader.standardTolWeight),
      },
      this.createGroup(
        LogHeaderSummaryTitles.APPLIED_TOLERANCE,
        {
          title: LogHeaderSummaryTitles.APPLIED_TOLERANCE_TYPE_CD,
          value: logHeader.appliedTolTypeCd ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        },
        {
          title: LogHeaderSummaryTitles.APPLIED_TOLERANCE_WEIGHT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader?.appliedTolWeight),
        }
      ),
      this.createGroup(
        LogHeaderSummaryTitles.CUSTOMER_TOLERANCE,
        {
          title: LogHeaderSummaryTitles.APPLIED_TOLERANCE_WEIGHT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeader?.customerTolWeight),
        },
        {
          title: LogHeaderSummaryTitles.MAD_CODE,
          value: logHeader.customerTolCustomerMadcode ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        },
        {
          title: LogHeaderSummaryTitles.PALLET_TOLERANCE_CUSTOMER_ID,
          value: logHeader.customerTolCustomerId,
        },
        {
          title: LogHeaderSummaryTitles.PARTY_TYPE,
          value: logHeader.customerTolPartyTypeCd ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        },
        {
          title: LogHeaderSummaryTitles.CUSTOMER_TOLERANCE_PARENT_TYPE_CD,
          value: logHeader.customerTolParentTypeCd ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        }
      ),
      this.createGroup(
        LogHeaderSummaryTitles.PALLET_TOLLERANCE,
        {
          title: LogHeaderSummaryTitles.APPLIED_TOLERANCE_WEIGHT,
          value: FormatNumberOrNaPipe.getFormattedNumberNaIfZero(logHeader?.palletTolWeight),
        },
        {
          title: LogHeaderSummaryTitles.MAD_CODE,
          value: logHeader.palletTolCustomerMadcode ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        },
        {
          title: LogHeaderSummaryTitles.PALLET_TOLERANCE_CUSTOMER_ID,
          value: logHeader.palletTolCustomerId,
        },
        {
          title: LogHeaderSummaryTitles.PALLET_TOLERANCE_AGREEMENT_ID,
          value: logHeader.palletTolAgreementId,
        },
        {
          title: LogHeaderSummaryTitles.PALLET_TOLERANCE_RULESET,
          value: logHeader.palletTolRuleset ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        }
      )
    );
  }

  getAdditionalDataGroup(logHeader: ExtendedLogHeader): LogHeaderGroup {
    const result: LogHeaderGroup = this.createGroup(
      LogHeaderSummaryTitles.ADDITIONAL_DATA,
      {
        title: LogHeaderSummaryTitles.SHIPMENT_INSTANCE_ID,
        value: logHeader.shipmentInstanceId ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
      },
      {
        title: LogHeaderSummaryTitles.RETRY_COUNT,
        value: FormatNumberOrNaPipe.getFormattedNumber(logHeader.retryCount),
      },
      { title: LogHeaderSummaryTitles.DISPLAY_IND, value: YesNoOptionPipeOrNa.formatOrNa(logHeader.displayInd) },
      this.getMorInfoCd(logHeader),
      {
        title: LogHeaderSummaryTitles.CORRELATION_ID,
        value: logHeader.correlationId ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
      },
      this.createGroup(
        LogHeaderSummaryTitles.CREATED_BY,
        { title: LogHeaderSummaryTitles.CREATED_BY, value: _get(logHeader, 'auditInfo.createdById') },
        {
          title: LogHeaderSummaryTitles.CREATED_TMST,
          value: this.getDateFromTmst(_get(logHeader, 'auditInfo.createdTimestamp')),
        },
        { title: LogHeaderSummaryTitles.PROGRAM_ID, value: _get(logHeader, 'auditInfo.createByPgmId') }
      ),
      this.createGroup(
        LogHeaderSummaryTitles.LAST_UPDATE_BY,
        { title: LogHeaderSummaryTitles.UPDATED_BY, value: _get(logHeader, 'auditInfo.updateById') },
        {
          title: LogHeaderSummaryTitles.LST_UPDT_TMST,
          value: this.getDateFromTmst(_get(logHeader, 'auditInfo.updatedTimestamp')),
        },
        { title: LogHeaderSummaryTitles.PROGRAM_ID, value: _get(logHeader, 'auditInfo.updateByPgmId') }
      )
    );

    return result;
  }

  createGroup(title: string, ...fields: (LogHeaderField | LogHeaderGroup)[]): LogHeaderGroup {
    const moreInformationTooltip = undefined;
    return { title, moreInformationTooltip, fields };
  }

  private getFormattedCurrency(value): string {
    return (
      (value && typeof value === 'number' && MiscUtil.formattedUsdAmount(value)) ??
      LogHeaderSummaryGroups.DEFAULT_NULL_VALUE
    );
  }

  private getDateFromTmst(value: Date): string {
    return (
      (value && DateHelper.getPortandTzFormattedDate(value, this.dateFormat)) ??
      LogHeaderSummaryGroups.DEFAULT_NULL_VALUE
    );
  }

  private getOperatorFullName(logHeader: ExtendedLogHeader): string {
    let result: string = '';
    if (logHeader?.operatorFirstName || logHeader?.operatorLastName) {
      result = logHeader.operatorFirstName;
      if (logHeader.operatorLastName) {
        result += ' ' + logHeader.operatorLastName;
      }
    }
    return result;
  }

  private getMorInfoCd(logHeader: ExtendedLogHeader): LogHeaderField {
    let result: LogHeaderField = null; // empty result
    if (this.userRoleService.currentUserHasWriteAccess()) {
      result = {
        title: LogHeaderSummaryTitles.MORE_INFO_CD,
        value: logHeader.moreInformationCd ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
      };
    }
    return result;
  }

  private getShipmentGroup(logHeaderShipment: LogHeaderShipment): LogHeaderGroup {
    let result: LogHeaderGroup;
    if (logHeaderShipment) {
      result = this.createGroup(
        LogHeaderSummaryTitles.SHIPMENT,
        // first line
        {
          title: LogHeaderSummaryTitles.PICKUP_DATE,
          value: logHeaderShipment.pickupDate ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
        },
        {
          title: LogHeaderSummaryTitles.FINAL_DELIVERED_IND,
          value: YesNoOptionPipe.format(logHeaderShipment.finalDeliveredInd),
        },
        {
          title: LogHeaderSummaryTitles.FINAL_DELIVERED_TMST,
          value: this.getDateFromTmst(logHeaderShipment.finalDeliveryDateTime),
        },
        {
          title: LogHeaderSummaryTitles.SHIPMENT_HAZMAT_IND,
          value: YesNoOptionPipeOrNa.formatOrNa(logHeaderShipment.hazmatInd),
        },
        {
          title: LogHeaderSummaryTitles.BILL_STATUS_CD,
          value: logHeaderShipment.billStatusCd,
        },
        // second line
        {
          title: LogHeaderSummaryTitles.SHIPMENT_TOTAL_PIECES_COUNT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeaderShipment.totalPieceCount),
        },
        {
          title: LogHeaderSummaryTitles.SHIPMENT_PALLET_COUNT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeaderShipment.totalPalletCount),
        },
        {
          title: LogHeaderSummaryTitles.SHIPMENT_LOOSE_PIECES_COUNT,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeaderShipment.loosePieceCount),
        },
        {
          title: LogHeaderSummaryTitles.SHIPMENT_MOTOR_MOVES,
          value: FormatNumberOrNaPipe.getFormattedNumber(logHeaderShipment.motorMovesNbr),
        },
        {
          title: LogHeaderSummaryTitles.BILL_CLASS_CD,
          value: logHeaderShipment.billClassCd,
        }
      );
    }
    result.moreInformationTooltip = 'Current shipment data for PRO';

    return result;
  }

  private getExceptionCode(logHeader: ExtendedLogHeader): LogHeaderField {
    let result: LogHeaderField;
    result = {
      title: LogHeaderSummaryTitles.EXEPTION_CODE,
      value: logHeader.exceptionCd ?? LogHeaderSummaryGroups.DEFAULT_NULL_VALUE,
    };
    if (this.userRoleService.currentUserHasWriteAccess()) {
      result.isLink = true;
      result.linkToolTipText = 'Click to see exception codes';
    }
    return result;
  }
}
