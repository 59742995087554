import { IOperatorFirstnamLastname } from '@shared/classes/entities/operator-firstname-lastname';
import { UiEntityTableEntity } from '@shared/classes/entities/ui-entity-table-entity';
import { ProNumberHelper } from '@shared/classes/pro-number-helper/pro-number-helper';
import { ProNumberUtil } from '@shared/classes/utils/pro-number-util';
import { LogDetail } from '@xpo-ltl/sdk-reweigh';
import { ScaleForkliftReport } from '../../../reweigh-calibration-headers/lib/scale-forklift-report';

export class OperatorLogDetail extends LogDetail implements IOperatorFirstnamLastname, UiEntityTableEntity {
  operatorFullName: string;
  equipmentFullId: string;
  equipmentIdSuffixNbrString: string;
  displayedChildProNbrTxt: string;

  /**
   * Cant put into EntitiesTransformer cause of circular dependencies
   * @param simpleLogDetail
   */
  static toOperatorLogDetail(simpleLogDetail: LogDetail): OperatorLogDetail {
    let result: OperatorLogDetail = null;
    if (simpleLogDetail) {
      result = new OperatorLogDetail();
      Object.assign(result, simpleLogDetail); // Object.assign(target, source)
      result.setOperatorFullName();
      result.setEquipmentFullId();
      result.setEquipmentIdSuffixNbrString();
      if (ProNumberUtil.isValidChildProNbrTxtAndNotNull(result.childProNbr)) {
        result.displayedChildProNbrTxt = ProNumberHelper.getStandardizedProNbr(result.childProNbr);
      }
    }
    return result;
  }

  setOperatorFullName() {
    if (this.operatorFirstName) {
      this.operatorFullName = this.operatorFirstName;
    }
    if (this.operatorLastName) {
      if (this.operatorFullName?.length > 0) {
        this.operatorFullName += ' ' + this.operatorLastName;
      }
    }
  }

  setEquipmentFullId() {
    this.equipmentFullId = ScaleForkliftReport.getEquipmentFullId(this.equipmentIdPrefix, this.equipmentIdSuffixNbr);
  }

  private setEquipmentIdSuffixNbrString() {
    this.equipmentIdSuffixNbrString = ScaleForkliftReport.leftPaddedEquipmentIdSuffix(this.equipmentIdSuffixNbr);
  }
}
