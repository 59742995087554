import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

import { AppRoutes } from '@shared/enums';
import { RoleCheckGuard } from '@shared/guards/role-check/role-check.guard';
import { SequentialGuard } from '@shared/guards/sequential/sequiential.guard';

// Skip SSO when running in Cypress test environment
const xpoAuthenticationGuard = !!window['Cypress'] ? [] : [XpoAuthenticationGuard];

const routerDefinitions: Routes = [
  {
    path: 'pro/:proNumber',
    loadChildren: () =>
      import('./reweigh-log-headers/reweigh-log-headers-home/reweigh-log-headers.module').then(
        (m) => m.ReweighLogHeadersModule
      ),
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [...xpoAuthenticationGuard, RoleCheckGuard],
    },
  },
  {
    path: AppRoutes.REWEIGH_LOG_HEADERS,
    loadChildren: () =>
      import('./reweigh-log-headers/reweigh-log-headers-home/reweigh-log-headers.module').then(
        (m) => m.ReweighLogHeadersModule
      ),
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [...xpoAuthenticationGuard, RoleCheckGuard],
    },
  },
  {
    path: `${AppRoutes.REWEIGH_CALIBRATION_HEADERS}`,
    loadChildren: () =>
      import('./reweigh-calibration-headers/components/reweigh-calibration.module').then(
        (m) => m.ReweighCalibrationModule
      ),
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [...xpoAuthenticationGuard, RoleCheckGuard],
    },
  },

  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: `${AppRoutes.REWEIGH_LOG_HEADERS}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
