import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ExtendedInspectionDimension } from '@shared/classes/entities/extended-inspection-dimension';
import { OperatorLogDetailHist } from '@shared/classes/entities/operator-log-detail-hist';
import { UiEntities } from '@shared/classes/entities/ui-entities';
import { UiEntity } from '@shared/classes/entities/ui-entity';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { Reweigh } from '@shared/classes/reweigh';
import { AgGridUtil } from '@shared/classes/utils/ag-grid-util';
import { UiEntityMatTableComponent } from '@shared/components/ui-entity-mat-table/ui-entity-mat-table-component';
import { CollapsableExpandableComponent } from '@shared/enums/collapsable-expandable-component';
import { ExtendedInspectionDimensionSrcCollection } from '@shared/enums/extended-inspection-dimension-src-collection';
import { ConstantsService } from '@shared/services/constants';
import { ReweighLogHeadersGridService } from '@shared/services/reweigh-log-headers-grid';
import { ReweighLogHeaderGridServiceColdefs } from '@shared/services/reweigh-log-headers-grid/reweigh-log-header-grid-service-coldefs';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import { ReweighService } from '@shared/services/reweigh-service/reweigh.service';
import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { UserRoleService } from '@shared/services/user-role';
import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';
import { DimensionSourceTypeCd } from '@xpo-ltl/sdk-common';
import { List } from 'immutable';
import { Subscription } from 'rxjs';
import { UiEntityTableCellDirective } from '../../../directives/ ui-entity-table-cell-directive';
import { ShipmentDimensionsCustomComponent } from '../mat-table-cell-projections/shipment-dimensions/shipment-dimensions-custom.component';

@Component({
  selector: 'app-shipment-dimensions',
  templateUrl: '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.html',
  styleUrls: [
    '../lib/css/reweigh-log-table-section.scss',
    '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ShipmentDimensionsComponent, useExisting: UiEntityMatTableComponent }],
})
export class ShipmentDimensionsComponent
  extends UiEntityMatTableComponent<ExtendedInspectionDimension, ShipmentDimensionsCustomComponent>
  implements OnDestroy, OnInit {
  @Input() collapserExpander: CollapsableExpandableComponent;

  /**
   * Cant be pushed on the parent
   * @param content
   */
  @ViewChild(MatSort, { static: false }) set content(content: ElementRef) {
    this.tableSort = content;
    if (this.tableSort) {
      this.dataSource.sort = this.tableSort;
    }
  }

  @ViewChildren(UiEntityTableCellDirective) uiEntityTableCellDirectiveViewChildren: QueryList<
    UiEntityTableCellDirective<OperatorLogDetailHist>
  >;

  protected reweighServiceSubscription: Subscription;

  protected currentReweigh: Reweigh;

  constructor(
    protected constantsService: ConstantsService,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    protected changeDetection: ChangeDetectorRef,
    protected userRoleService: UserRoleService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected reweighService: ReweighService,
    protected reweighLogSummaryService: ReweighLogSummaryService,
    protected appRef: ApplicationRef
  ) {
    super(reweighLogSummaryService, changeDetection);
  }

  /* tslint:disable use-lifecycle-interface */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnInit(): void {
    this.setFromReweigh(this.reweighService.reweigh);
    this.isTitleSectionDisplayed = false;
    this.reweighServiceSubscription = this.reweighService.reweighSubject$.subscribe((aReweigh: Reweigh) => {
      this.setFromReweigh(aReweigh);
      this.changeDetection.markForCheck();
    });
    // when dims are changed and toggled
    this.reweighService.shipmentDimensionsFilteredSubject$.subscribe(
      (extendedInspectionDimensionsList: List<ExtendedInspectionDimension>) => {
        this.dataSource.data = extendedInspectionDimensionsList.toArray();
      }
    );
    this.changeDetection.markForCheck();
  }

  getComponentFactoryToProject(): ComponentFactory<ShipmentDimensionsCustomComponent> {
    // tslint:disable-next-line:max-line-length
    const lComponentFactoryToProject: ComponentFactory<ShipmentDimensionsCustomComponent> = this.componentFactoryResolver.resolveComponentFactory<
      ShipmentDimensionsCustomComponent
    >(ShipmentDimensionsCustomComponent);
    return lComponentFactoryToProject;
  }

  getElementTableHeaderTitle(aUiEntityProperty: UiEntityProperty): string {
    const agGridColumnDefs: XpoAgGridBoardColumn[] = [
      ...ReweighLogHeaderGridServiceColdefs.getPrimaryColumns(this.userRoleService),
      ...ReweighLogHeadersGridService.secondaryColumns,
    ];
    const result: string = AgGridUtil.getHeaderNameFromColumnDefOrUiEntityDef(
      aUiEntityProperty,
      agGridColumnDefs,
      [...UiEntities.COMMON_ENTITIES_FOR_LABELS.properties, ...this.getUiEntity().properties],
      true
    );
    return result;
  }

  getUiEntity(): UiEntity {
    return UiEntities.EXTENDED_INSPECTION_DIMENSION;
  }

  getMainTitle(): string {
    return 'Dimensions for header: ' + this.currentReweigh?.getLogHeaderId();
  }

  isLoadDataButtonDisplayed(): boolean {
    return false;
  }

  getSortDefaultColumnName(): string {
    return 'proNbr';
  }

  ngOnDestroy(): void {
    this.reweighServiceSubscription.unsubscribe();
  }

  setFromReweigh(aReweigh: Reweigh) {
    this.currentReweigh = aReweigh;
  }

  getComponentForColumnsExpandCollapse(): CollapsableExpandableComponent {
    return CollapsableExpandableComponent.ShipmentDimensionsFullSum;
  }

  getCollapserExpander(): CollapsableExpandableComponent | undefined {
    return this.collapserExpander;
  }
}
