import { ExtendedInspectionDimension } from '@shared/classes/entities/extended-inspection-dimension';
import { IllegalArgumentError } from '@shared/classes/error/illegal-argument-error';
import { IllegalStateError } from '@shared/classes/error/illegal-state-error';
import { ExtendedInspectionDimensionSrcCollection } from '@shared/enums/extended-inspection-dimension-src-collection';
import { ShipmentDimensionsFilterType } from '@shared/enums/shipment-dimensions-filter-type.enum';
import { List } from 'immutable';

export class InspectionDimensions {
  protected shipmentDimensionsListFromInspectionApi: List<ExtendedInspectionDimension>;

  protected inspectionDimensions: List<ExtendedInspectionDimension> = List();
  protected operationDimensions: List<ExtendedInspectionDimension> = List();
  protected handlingUnitDimensions: List<ExtendedInspectionDimension> = List();
  protected inspectionDimensionHistory: List<ExtendedInspectionDimension> = List();

  constructor(aShipmentDimensionsFromInspectionApiList: Immutable.List<ExtendedInspectionDimension>) {
    if (!aShipmentDimensionsFromInspectionApiList) {
      throw new IllegalArgumentError('aShipmentDimensionsFromInspectionApiList is null');
    } else {
      this.shipmentDimensionsListFromInspectionApi = aShipmentDimensionsFromInspectionApiList;
      if (this.hasDimensions()) {
        this.shipmentDimensionsListFromInspectionApi.forEach((lDim: ExtendedInspectionDimension) => {
          switch (lDim.srcCollection) {
            case ExtendedInspectionDimensionSrcCollection.HANDLING_UNIT_DIMENSIONS:
              this.handlingUnitDimensions = this.handlingUnitDimensions.push(lDim);
              break;
            case ExtendedInspectionDimensionSrcCollection.INSPECTION_DIMENSIONS:
              this.inspectionDimensions = this.inspectionDimensions.push(lDim);
              break;
            case ExtendedInspectionDimensionSrcCollection.INSPECTION_DIMENSIONS_HISTORY:
              this.inspectionDimensionHistory = this.inspectionDimensionHistory.push(lDim);
              break;
            case ExtendedInspectionDimensionSrcCollection.OPERATION_DIMENSIONS:
              this.operationDimensions = this.operationDimensions.push(lDim);
              break;
            default:
              throw new IllegalStateError('A dimension srcCollection is not treated' + lDim.srcCollection);
          }
        });
      }
    }
  }

  /**
   * LEI-2417
   *
   * - Use endpoint with parameters to return Inspection history, handling unit dims, Ops dims.
   * - For Inspection dims, display most recent historyDims by capturedTimestamp with option to display all dims.
   * - For Ops Dims display Handling unit dims if any are avail, if not display Ops Dims.
   *
   * @param aIsAllShipmentDimensionsDisplayed
   */
  toAllShipmentDimensionsDisplayed(
    aIsAllShipmentDimensionsDisplayed: ShipmentDimensionsFilterType
  ): List<ExtendedInspectionDimension> {
    if (!aIsAllShipmentDimensionsDisplayed) {
      throw new IllegalStateError('this.shipmentDimensionsFilterType should not be undefined');
    }

    let lAddHandlingUnitDimensions: boolean = false;
    let lAddOperationDimensions: boolean = false;
    let result: List<ExtendedInspectionDimension> = List();

    if (this.hasDimensions()) {
      switch (aIsAllShipmentDimensionsDisplayed) {
        case ShipmentDimensionsFilterType.ALL:
          result = this.getSortedAndFilteredAllHistDimensions();
          lAddHandlingUnitDimensions = true;
          lAddOperationDimensions = true;
          break;
        case ShipmentDimensionsFilterType.SUMMARY:
          result = this.getAddedMostRecentHistDims(result);
          if (result.isEmpty()) {
            lAddHandlingUnitDimensions = true;
            if (!this.hasHandlingUnitsDimensions()) {
              lAddOperationDimensions = true;
            }
          }
          break;
        case ShipmentDimensionsFilterType.ALL_BUT_DUPLICATES:
          result = this.getAddedMostRecentHistDims(result);
          lAddHandlingUnitDimensions = true;
          if (result.isEmpty()) {
            if (!this.hasHandlingUnitsDimensions()) {
              lAddOperationDimensions = true;
            }
          }
          break;
      }
      // add handlingUnit dims
      if (lAddHandlingUnitDimensions && this.hasHandlingUnitsDimensions()) {
        const lHuDims: List<ExtendedInspectionDimension> = this.handlingUnitDimensions.sort((a, b) => {
          const aCapturedTimestamp: number = (a.capturedTimestamp as any) as number;
          const bCapturedTimestamp: number = (b.capturedTimestamp as any) as number;
          let lRes: number = 0;
          if (a.dimensionSequenceNbr && b.dimensionSequenceNbr) {
            if (aCapturedTimestamp && bCapturedTimestamp) {
              lRes = a.dimensionSequenceNbr - b.dimensionSequenceNbr;
            } else if (aCapturedTimestamp === bCapturedTimestamp) {
              lRes = bCapturedTimestamp - aCapturedTimestamp;
            }
          }
          return lRes;
        });
        result = result.concat(lHuDims);
      }
      // add ops dims
      if (lAddOperationDimensions && this.hasOperationDimensions()) {
        const lOpsDims: List<ExtendedInspectionDimension> = this.operationDimensions.sort((a, b) => {
          const aCapturedTimestamp: number = (a.capturedTimestamp as any) as number;
          const bCapturedTimestamp: number = (b.capturedTimestamp as any) as number;
          let lRes: number = 0;
          if (a.dimensionSequenceNbr && b.dimensionSequenceNbr) {
            if (aCapturedTimestamp && bCapturedTimestamp) {
              lRes = a.dimensionSequenceNbr - b.dimensionSequenceNbr;
            } else if (aCapturedTimestamp === bCapturedTimestamp) {
              lRes = bCapturedTimestamp - aCapturedTimestamp;
            }
          }
          return lRes;
        });
        result = result.concat(lOpsDims);
      }
    }

    return result;
  }

  hasDimensions(): boolean {
    return !this.shipmentDimensionsListFromInspectionApi.isEmpty();
  }

  hasInspectionDimensions(): boolean {
    return !this.inspectionDimensions.isEmpty();
  }

  hasOperationDimensions(): boolean {
    return !this.operationDimensions.isEmpty();
  }

  hasInspectionDimensionsHist(): boolean {
    return !this.inspectionDimensionHistory.isEmpty();
  }

  hasHandlingUnitsDimensions() {
    return !this.handlingUnitDimensions.isEmpty();
  }

  /**
   *
   * @protected
   */
  protected createSortedHistByCapturedTmstDesc(): List<ExtendedInspectionDimension> {
    const result: List<ExtendedInspectionDimension> = this.inspectionDimensionHistory.sort((a, b) => {
      // they are not dates
      const aCapturedTimestamp: number = (a.capturedTimestamp as any) as number;
      const bCapturedTimestamp: number = (b.capturedTimestamp as any) as number;
      if (aCapturedTimestamp && bCapturedTimestamp) {
        return bCapturedTimestamp - aCapturedTimestamp;
      } else {
        return 0;
      }
    });
    return result;
  }

  protected getAddedMostRecentHistDims(
    src: Immutable.List<ExtendedInspectionDimension>
  ): Immutable.List<ExtendedInspectionDimension> {
    let result: Immutable.List<ExtendedInspectionDimension> = List(src);
    if (this.hasInspectionDimensionsHist()) {
      // show first inspectionDimensionsHistory latest set of dims grouped by capturedTimestamp
      // as we might have multiple seqNbr but they have same capturedTimestamp we can filter them by that
      // tslint:disable-next-line:max-line-length
      const lSortedHistAsMostRecentDimension: List<ExtendedInspectionDimension> = this.createSortedHistByCapturedTmstDesc();
      // tslint:disable-next-line:max-line-length
      const mostRecentExtendedInspectionDimensionCapturedTimestamp: number = (lSortedHistAsMostRecentDimension.get(0)
        .capturedTimestamp as any) as number;
      lSortedHistAsMostRecentDimension.forEach((lSortedInspectionHistDims: ExtendedInspectionDimension) => {
        if (
          mostRecentExtendedInspectionDimensionCapturedTimestamp ===
          ((lSortedInspectionHistDims.capturedTimestamp as any) as number)
        ) {
          result = result.push(lSortedInspectionHistDims);
        } // else do nothing, cant break on foreach
      });
    }

    return result;
  }

  private getSortedAndFilteredAllHistDimensions(): List<ExtendedInspectionDimension> {
    let result: List<ExtendedInspectionDimension> = List();
    if (this.hasInspectionDimensionsHist()) {
      let lHists: List<ExtendedInspectionDimension> = this.inspectionDimensionHistory;
      lHists = lHists.sort((a, b) => {
        // they are not dates
        const aCapturedTimestamp: number = (a.capturedTimestamp as any) as number;
        const bCapturedTimestamp: number = (b.capturedTimestamp as any) as number;
        if (aCapturedTimestamp && bCapturedTimestamp) {
          let lRes: number = 0;
          if (aCapturedTimestamp === bCapturedTimestamp) {
            if (a.dimensionSequenceNbr && b.dimensionSequenceNbr) {
              lRes = a.dimensionSequenceNbr - b.dimensionSequenceNbr;
            } else {
              lRes = 0;
            }
          } else {
            lRes = bCapturedTimestamp - aCapturedTimestamp;
          }
          return lRes;
        } else {
          return 0;
        }
      });
      result = result.concat(lHists);
    }

    return result;
  }
}
