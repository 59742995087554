import { Injectable } from '@angular/core';
import { ExtendedInspectionDimension } from '@shared/classes/entities/extended-inspection-dimension';
import { ExtendedLiftScaleCalibrationHeader } from '@shared/classes/entities/extended-lift-scale-calibration-header';
import { ExtendedLogHeader } from '@shared/classes/entities/extended-log-header';
import { Reweigh } from '@shared/classes/reweigh';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { InspectionsApiServiceWrapper } from '@shared/services/sdk/inspections-api-service-wrapper.service';

import { ExtendedLiftScaleCalibrationHeaderUniq } from '@shared/classes/entities/extended-lift-scale-calibration-header-uniq';
import { IllegalArgumentError } from '@shared/classes/error/illegal-argument-error';
import { IllegalStateError } from '@shared/classes/error/illegal-state-error';
import { InspectionsApiError } from '@shared/classes/error/inspections-api-error';
import { ReweighApiError } from '@shared/classes/error/reweigh-api-error';
import { InspectionDimensions } from '@shared/classes/inspection-dimensions';
import { ShipmentDimensionsFilterType } from '@shared/enums/shipment-dimensions-filter-type.enum';
import { LogHeaderShipment } from '@xpo-ltl/sdk-reweigh';
import { List } from 'immutable';
import { BehaviorSubject, throwError } from 'rxjs';
import { ReweighApiServiceWrapper } from '../sdk/reweigh-api-service-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class ReweighService {
  reweighSubject$: BehaviorSubject<Reweigh> = new BehaviorSubject<Reweigh>(undefined);
  shipmentDimensionsFilteredSubject$: BehaviorSubject<List<ExtendedInspectionDimension>> = new BehaviorSubject<
    List<ExtendedInspectionDimension>
  >(List());
  protected shipmentDimensionsFromInspectionApi: List<ExtendedInspectionDimension> = List();
  protected shipmentDimensionsFilterType: ShipmentDimensionsFilterType = ShipmentDimensionsFilterType.SUMMARY;

  reweigh: Reweigh;

  constructor(
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    protected inspectionsApiServiceWrapper: InspectionsApiServiceWrapper,
    protected reweighAppNotificationService: ReweighAppNotificationService
  ) {}

  loadByLogHeaderIdIfNotLoaded(logHeaderId: number) {
    // loads only if logHeaderId is different
    if (!this.reweigh || logHeaderId !== this.reweigh?.getLogHeaderId()) {
      if (logHeaderId && !isNaN(logHeaderId as any)) {
        this.reweigh = new Reweigh();
        // logheader
        this.reweighApiServiceWrapper.getReweighLog(logHeaderId).subscribe((logHeader: ExtendedLogHeader) => {
          if (!logHeader) {
            // its a popup no way to navigate back...
            throw new ReweighApiError('no logHeader went back from getReweighLog');
          }
          this.reweigh.logHeader = logHeader;
          if (!isNaN(this.reweigh.logHeader.shipmentInstanceId)) {
            this.setInspectionDimensionsFromInspectionApi(this.reweigh.logHeader.shipmentInstanceId);
          } else {
            throw new IllegalStateError('No shipmentInstanceId cant get shipment dimensions');
          }
          this.reweighSubject$.next(this.reweigh);
        });
        // shipment
        this.reweighApiServiceWrapper
          .getShipmentForLogHeaderByLogHeaderId(logHeaderId)
          .subscribe((aLogHeaderShipment: LogHeaderShipment) => {
            this.reweigh.logHeaderShipment = aLogHeaderShipment;
            this.reweighSubject$.next(this.reweigh);
          });
        // scaleTests
        this.reweighApiServiceWrapper
          .listLatestScaleTestsByLogHeaderId(logHeaderId)
          .subscribe((aLiftScaleCalibrationHeaders: List<ExtendedLiftScaleCalibrationHeader>) => {
            this.reweigh.liftScaleCalibrationHeaders = aLiftScaleCalibrationHeaders;
            this.reweigh.liftScaleCalibrationHeadersUniq = this.getLiftScaleCalibrationHeadersUniq(
              aLiftScaleCalibrationHeaders
            );
            this.reweighSubject$.next(this.reweigh);
          });
      } else {
        const msg: string = 'given logHeaderId is not a number:' + logHeaderId;
        console.error(msg);
        throw new IllegalArgumentError(msg);
      }
    } else {
      if (this.reweigh) {
        this.reweighSubject$.next(this.reweigh);
      } else {
        throw new IllegalArgumentError('current Reweigh seems to be the same as previous but not set, correct me');
      }
    }
  }

  getProNumber(): string {
    const result: string = this.getLogHeader()?.proNbr;
    if (result?.length === 0) {
      const msg: string = 'ProNumber is not available';
      this.reweighAppNotificationService.error(msg);
      throw Error(msg);
    } else {
      return result;
    }
  }

  getLogHeader(): ExtendedLogHeader {
    return this.reweigh?.logHeader;
  }

  getLogHeaderCreateTimestamp(): Date {
    const result: Date = this.getLogHeader()?.auditInfo?.createdTimestamp;
    if (!result) {
      const msg: string = 'createdTimestamp is not available';
      this.reweighAppNotificationService.error(msg);
      throw Error(msg);
    } else {
      return result;
    }
  }

  getShipmentInstanceId(): number {
    const result: number = this.getLogHeader()?.shipmentInstanceId;
    if (isNaN(result)) {
      const msg: string = 'shipmentInstanceId is not available';
      this.reweighAppNotificationService.error(msg);
      throw Error(msg);
    } else {
      return result;
    }
  }

  hasInspectionDimensions(): boolean {
    return !this.shipmentDimensionsFromInspectionApi.isEmpty();
  }

  /**
   * does a next on shipmentDimensionsFilteredSubject$
   *
   * @param aShipmentInstId
   * @protected
   */
  protected setInspectionDimensionsFromInspectionApi(aShipmentInstId: number) {
    this.inspectionsApiServiceWrapper.getInspectionDimensionsForShipment(aShipmentInstId).subscribe(
      (aInspectionDimensionsList: List<ExtendedInspectionDimension>) => {
        this.shipmentDimensionsFromInspectionApi = aInspectionDimensionsList;
        // will call shipmentDimensionsFilteredSubject$
        this.setFilteredShipmentDimensionsFromShipmentDimensions();
      },
      (error) => {
        const msg: string = 'Error setting inspection dimensions';
        this.reweighAppNotificationService.error(msg);
        throwError(new InspectionsApiError(msg, error));
      }
    );
  }

  private getLiftScaleCalibrationHeadersUniq(
    aLiftScaleCalibrationHeaders: List<ExtendedLiftScaleCalibrationHeader>
  ): List<ExtendedLiftScaleCalibrationHeaderUniq> {
    const result: ExtendedLiftScaleCalibrationHeaderUniq[] = [];
    // convert to Uniq Objects
    aLiftScaleCalibrationHeaders.forEach((lCalHeader: ExtendedLiftScaleCalibrationHeader) => {
      // adding only if headerId is different (
      if (
        !result.find((lExtendedLiftScaleCalibrationHeaderUniq) =>
          lExtendedLiftScaleCalibrationHeaderUniq.equals(lCalHeader)
        )
      ) {
        // tslint:disable-next-line:max-line-length
        const lUniq: ExtendedLiftScaleCalibrationHeaderUniq = ExtendedLiftScaleCalibrationHeaderUniq.toExtendedLiftScaleCalibrationHeaderUniq(
          lCalHeader
        );
        result.push(lUniq);
      }
    });

    // console.debug('src,result:', aLiftScaleCalibrationHeaders, result);
    return List(result);
  }

  setFilteredShipmentDimensionsFromShipmentDimensions() {
    if (!this.shipmentDimensionsFilterType) {
      throw new IllegalStateError('this.shipmentDimensionsFilterType should not be undefined');
    }
    const lInspectionDimensions: InspectionDimensions = new InspectionDimensions(
      this.shipmentDimensionsFromInspectionApi
    );
    // tslint:disable-next-line:max-line-length
    const lFilteredInspectionDimensionsList: List<ExtendedInspectionDimension> = lInspectionDimensions.toAllShipmentDimensionsDisplayed(
      this.shipmentDimensionsFilterType
    );
    this.shipmentDimensionsFilteredSubject$.next(lFilteredInspectionDimensionsList);
  }

  setIsAllShipmentDimensionsDisplayed(aDimsFilterType: ShipmentDimensionsFilterType) {
    this.setShipmentDimensionsFilterType(aDimsFilterType);
    this.setFilteredShipmentDimensionsFromShipmentDimensions();
  }

  setShipmentDimensionsFilterType(aDimsFilterType: ShipmentDimensionsFilterType) {
    this.shipmentDimensionsFilterType = aDimsFilterType;
  }
}
