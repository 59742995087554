import { IOperatorFirstnamLastname } from '@shared/classes/entities/operator-firstname-lastname';
import { OperatorLogDetail } from '@shared/classes/entities/operator-log-detail';
import { ProNumberHelper } from '@shared/classes/pro-number-helper/pro-number-helper';
import { ReweighStatusCd } from '@xpo-ltl/sdk-common';
import { LogHeader } from '@xpo-ltl/sdk-reweigh';

export class ExtendedLogHeader extends LogHeader implements IOperatorFirstnamLastname {
  operatorFullName: string;
  hasErrorDetail: boolean = false;
  displayedProNbrTxt: string;
  isManualCertificateDownloadable: boolean = false;

  // trick to have OperatorLogDetail type here
  logDetail: OperatorLogDetail[];

  static toExtendedLogHeader(logHeader: LogHeader): ExtendedLogHeader {
    let result: ExtendedLogHeader = null;
    if (logHeader) {
      result = new ExtendedLogHeader();
      Object.assign(result, logHeader); // Object.assign(target, source)
      result.setExtendedData();
    }
    return result;
  }

  protected setExtendedData() {
    this.setOperatorFullName();
    this.hasErrorDetail = this.errorDetail && this.errorDetail.length > 0;
    if (this.hasErrorDetail) {
      try {
        this.errorDetail = JSON.parse(this.errorDetail);
      } catch (e) {
        // ignoring, we can have 'trimmed: {invalidJsonString'
        // console.info('setExtendedData json parse error of:', this.errorDetail);
      }
    }
    if (this.proNbr?.length > 0) {
      this.displayedProNbrTxt = ProNumberHelper.getStandardizedProNbr(this.proNbr);
    }
    this.setIsManualCertificateDownloadable();
  }

  setOperatorFullName() {
    if (this.operatorFirstName) {
      this.operatorFullName = this.operatorFirstName;
    }
    if (this.operatorLastName) {
      if (this.operatorFullName?.length > 0) {
        this.operatorFullName += ' ' + this.operatorLastName;
      }
    }
    if (this.logDetail && this.logDetail.length > 0) {
      for (let i = 0; i < this.logDetail.length; i++) {
        const simpleLogDetail = this.logDetail[i];
        this.logDetail[i] = OperatorLogDetail.toOperatorLogDetail(simpleLogDetail);
      }
    }
  }

  protected setIsManualCertificateDownloadable() {
    let lIsManualCertificateDownloadable: boolean = false;
    if (!this.imageCreatedInd) {
      if (
        this.statusCd === ReweighStatusCd.APPROVED ||
        this.statusCd === ReweighStatusCd.WITHIN_TOLERANCE ||
        this.statusCd === ReweighStatusCd.PENDING ||
        this.statusCd === ReweighStatusCd.ERROR
      ) {
        lIsManualCertificateDownloadable = true;
      }
    }
    this.isManualCertificateDownloadable = lIsManualCertificateDownloadable;
  }
}
