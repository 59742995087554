<!--<ng-template *ngIf="logHeaderSummaryGroups;else noLogHeaderSummaryGroups">-->
<div class="overall">
  <div class="expansion-controls">
    <a xpo-regular-link (click)="accordion.openAll()"
      >Expand All
      <mat-icon>expand_more</mat-icon>
    </a>
    <span class="separator">/</span>
    <a xpo-regular-link (click)="accordion.closeAll()"
      >Collapse All
      <mat-icon>expand_less</mat-icon>
    </a>
  </div>
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel
      class="main-card"
      [expanded]="true"
      *ngFor="let group of logHeaderSummaryGroups?.groups; index as groupIndex"
      data-test="reweig-log-summary-expansion-panel"
      xpoExpansionPanelDark
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ group.title }}
          <xpo-icon
            *ngIf="group.moreInformationTooltip"
            iconName="info"
            class="white-icon more-info"
            [matTooltip]="group.moreInformationTooltip"
          ></xpo-icon>
        </mat-panel-title>
        <!--        only for Reweigh title-->
        <ng-container *ngIf="groupIndex === 0">
          <a
            xpo-link
            class="header-link"
            (click)="navigateToShipmentDetails($event)"
            matTooltip="Open shipment details"
          >
            SHIPMENT DETAILS
          </a>
        </ng-container>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container *ngFor="let firstGroup of group.fields">
          <ng-container *ngIf="firstGroup">
            <xpo-card class="sub-card" *ngIf="firstGroup.fields; else singleInfoTpl">
              <xpo-card-header class="sub-card-header">
                <xpo-card-title>
                  {{ firstGroup.title }}
                </xpo-card-title>
              </xpo-card-header>
              <xpo-card-content>
                <ng-container *ngFor="let secondGroup of firstGroup.fields">
                  <div class="field" *ngIf="isFieldVisible(secondGroup)">
                    <label class="field_label">{{ secondGroup.title }}</label>
                    <a
                      xpo-link
                      class="field_value"
                      (click)="onSummaryGroupItemLinkClicked(secondGroup)"
                      [matTooltip]="secondGroup.linkToolTipText || null"
                      matTooltipPosition="right"
                      *ngIf="secondGroup.isLink; else secondGroupStandardInput"
                      >{{ secondGroup.value }}</a
                    >
                  </div>
                  <ng-template #secondGroupStandardInput>
                    <span class="field_value" *ngIf="secondGroup.value && isFieldVisible(secondGroup)">{{
                      secondGroup.value
                    }}</span>
                  </ng-template>
                </ng-container>
              </xpo-card-content>
            </xpo-card>
            <ng-template #singleInfoTpl>
              <div class="field" *ngIf="isFieldVisible(firstGroup)">
                <label class="field_label">{{ firstGroup.title }}</label>
                <a
                  xpo-link
                  class="field_value"
                  (click)="onSummaryGroupItemLinkClicked(firstGroup)"
                  [matTooltip]="firstGroup.linkToolTipText || null"
                  matTooltipPosition="right"
                  *ngIf="firstGroup.isLink; else firstGroupStandardInput"
                  >{{ firstGroup.value }}</a
                >
              </div>
              <ng-template #firstGroupStandardInput>
                <span class="field_value">
                  {{ firstGroup.value }}
                  <button
                    *ngIf="firstGroup?.hasCustomTemplate && imageCreatedInd"
                    mat-icon-button
                    matTooltip="Click to download certificate"
                    matTooltipPosition="after"
                    (click)="onDownloadCertificateClicked()"
                  >
                    <mat-icon class="mat-icon-download">download</mat-icon>
                  </button>
                  <!--                  manual certificate download-->
                  <button
                    *ngIf="
                      hasManualCreateCerficateRole &&
                      firstGroup?.hasCustomTemplate &&
                      !imageCreatedInd &&
                      extendedLogHeader?.isManualCertificateDownloadable
                    "
                    mat-icon-button
                    matTooltip="Click to download Manual Certificate"
                    matTooltipPosition="after"
                    (click)="onDownloadManualCertificateClicked()"
                  >
                    <mat-icon class="mat-icon-download">download</mat-icon>M
                  </button>
                  <!-- hide this until we can use PDF -->
                  <!-- <button *ngIf="firstGroup?.hasCustomTemplate && imageCreatedInd" mat-icon-button matTooltip="Click to open certificate" matTooltipPosition="after" (click)="onViewCertificateClicked()" >
                <mat-icon class="mat-icon-download">description</mat-icon>
              </button> -->
                </span>
              </ng-template>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
    <!--    Detail records-->
    <mat-expansion-panel class="main-card detail-card" [expanded]="true" xpoExpansionPanelDark>
      <mat-expansion-panel-header>
        <mat-panel-title
          >Detail Records <span class="count-on-black-bg">{{ (logDetails$ | async).size }}</span></mat-panel-title
        >
        <mat-slide-toggle
          (click)="
            onCollapseExpandColumnsClicked($event, ExpandableCollapsableComponent.ReweighLogDetailsComponentFullSum)
          "
          >{{ ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT }}
        </mat-slide-toggle>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div
          class="reweigh-header-record-details_no-records"
          *ngIf="(logDetails$ | async).isEmpty(); else showLogDetailsTpl"
        >
          <mat-icon>insert_drive_file</mat-icon>
          No records to show
        </div>
        <ng-template #showLogDetailsTpl>
          <div class="scrollable-auto">
            <reweigh-log-details
              class="reweigh-header-record-details"
              [tableDataAsList]="logDetails$ | async"
              [isHeaderTitleDisplayedInput]="false"
              [collapserExpander]="ExpandableCollapsableComponent.ReweighLogDetailsComponentFullSum"
            ></reweigh-log-details>
          </div>
        </ng-template>
      </ng-template>
    </mat-expansion-panel>
    <!--    LiftScale tests-->
    <mat-expansion-panel
      class="main-card detail-card"
      [expanded]="true"
      xpoExpansionPanelDark
      *ngIf="hasCurrentUserWriteAccess"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          >Scale Tests
          <span class="count-on-black-bg">{{ (liftScaleCalibrationHeadersUniq$ | async).size }}</span>
          <xpo-icon iconName="info" class="white-icon more-info" matTooltip="Only unique Scale Tests"></xpo-icon>
        </mat-panel-title>
        <!--        collapse expand columns -->
        <mat-slide-toggle
          *ngIf="!(liftScaleCalibrationHeadersUniq$ | async).isEmpty()"
          (click)="onCollapseExpandColumnsClicked($event, ExpandableCollapsableComponent.ScaleTestsUniqFullSum)"
          >{{ ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT }}
        </mat-slide-toggle>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div
          class="reweigh-header-record-details_no-records"
          *ngIf="(liftScaleCalibrationHeadersUniq$ | async)?.isEmpty(); else showLogDetailsTpl"
        >
          <mat-icon>insert_drive_file</mat-icon>
          No records to show
        </div>
        <ng-template #showLogDetailsTpl>
          <div class="scrollable-auto">
            <app-reweigh-log-scale-tests-uniq
              class="reweigh-header-record-details"
              [tableData]="liftScaleCalibrationHeadersUniq$ | async"
              [isHeaderTitleDisplayedInput]="false"
              [isFullTableDisplayed]="true"
              [collapserExpander]="ExpandableCollapsableComponent.ScaleTestsUniqFullSum"
            ></app-reweigh-log-scale-tests-uniq>
          </div>
        </ng-template>
      </ng-template>
    </mat-expansion-panel>
    <!--    Dimensions-->
    <mat-expansion-panel
      class="main-card detail-card"
      [expanded]="true"
      xpoExpansionPanelDark
      *ngIf="hasCurrentUserWriteAccess"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          >Shipment Dimensions
          <span class="count-on-black-bg">{{ shipmentDimensionsCount$ | async }}</span>
        </mat-panel-title>
        <!--        dims display options -->

        <mat-form-field
          appearance="standard"
          (click)="$event.stopPropagation()"
          style="margin-left: auto; margin-right: auto"
        >
          <mat-select
            placeholder="Select without label"
            name="shipmentDimsFilterSelect"
            [(value)]="dimsDefaultSelectedOption"
            (selectionChange)="onShipmentDimsFilterSelectChanged($event)"
            xpoSelect
          >
            <mat-option
              *ngFor="let lMatSelectOption of shipmentDimensionsSelectOptions"
              [value]="lMatSelectOption.value"
              >{{ lMatSelectOption.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--        <mat-slide-toggle *ngIf="hasShipmentDimensions$ | async" (click)="onAllDimsToggleClicked($event)"-->
        <!--        >{{ ReweighLogSummaryBase.ALL_DIMS_TOGGLE_LABEL_CONTENT }}-->
        <!--        </mat-slide-toggle>-->
        <!--        collapse/expand-->
        <mat-slide-toggle
          *ngIf="hasShipmentDimensions$ | async"
          (click)="onCollapseExpandColumnsClicked($event, ExpandableCollapsableComponent.ShipmentDimensionsFullSum)"
          >{{ ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT }}
        </mat-slide-toggle>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div
          class="reweigh-header-record-details_no-records"
          *ngIf="!(hasShipmentDimensions$ | async); else showShmDimsTpl"
        >
          <mat-icon>insert_drive_file</mat-icon>
          No records to show
        </div>
        <ng-template #showShmDimsTpl>
          <div class="scrollable-auto">
            <app-shipment-dimensions
              [collapserExpander]="ExpandableCollapsableComponent.ShipmentDimensionsFullSum"
            ></app-shipment-dimensions>
          </div>
        </ng-template>
      </ng-template>
    </mat-expansion-panel>

    <!--History-->
    <mat-expansion-panel
      class="main-card detail-card"
      [expanded]="true"
      xpoExpansionPanelDark
      *ngIf="hasCurrentUserWriteAccess"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>Header History Records</mat-panel-title>
        <mat-slide-toggle
          (click)="onCollapseExpandColumnsClicked($event, ExpandableCollapsableComponent.HeaderHistoryFullSum)"
          >{{ ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT }}
        </mat-slide-toggle>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="scrollable-auto">
          <app-reweigh-log-header-hist
            [logHeaderId]="logHeaderId"
            [collapserExpander]="ExpandableCollapsableComponent.HeaderHistoryFullSum"
          ></app-reweigh-log-header-hist>
        </div>
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="main-card detail-card"
      [expanded]="true"
      xpoExpansionPanelDark
      *ngIf="hasCurrentUserWriteAccess"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>Detail History Records</mat-panel-title>
        <mat-slide-toggle
          (click)="onCollapseExpandColumnsClicked($event, ExpandableCollapsableComponent.DetailsHistFullSum)"
          >{{ ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT }}
        </mat-slide-toggle>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="scrollable-auto">
          <app-reweigh-log-detail-hist
            [logHeaderId]="logHeaderId"
            [collapserExpander]="ExpandableCollapsableComponent.DetailsHistFullSum"
          ></app-reweigh-log-detail-hist>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
  <!--</ng-template>-->
  <!--<ng-template #noLogHeaderSummaryGroups>-->
  <!--  Loading...-->
  <!--</ng-template>-->
</div>
