<div mat-dialog-title class="dialog-title">
  <h1 mat-dialog-title *ngIf="logHeader">Log Header #{{ logHeader.logHeaderId }}</h1>
  <div class="close">
    <button mat-icon-button (click)="close()" matTooltip="Click to close or Escape or Click outside the dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="info-dialog_content">
  <!--logHeader-->
  <ng-container class="logHeader-container">
    <div class="logHeader">
      <dl *ngIf="logHeader">
        <dt>Pro Number</dt>
        <dd>{{ logHeader.proNbr | proNumber }}</dd>
        <dt>Exception Code</dt>
        <dd>{{ logHeader.exceptionCd || 'None' }}</dd>
        <dt>Status code</dt>
        <dd>{{ logHeader.statusCd || 'None' }}</dd>
      </dl>
      <dl *ngIf="logHeaderHist">
        <dt>Pro Number</dt>
        <dd>{{ logHeaderHist.proNbr | proNumber }}</dd>
        <dt>Exception Code</dt>
        <dd>{{ logHeaderHist.exceptionCd || 'None' }}</dd>
        <dt>Status code</dt>
        <dd>{{ logHeaderHist.statusCd || 'None' }}</dd>
      </dl>
    </div>
    <div class="logHeader-exception">
      <h2 *ngIf="currentReweighException">Current exception</h2>
      <dl *ngIf="currentReweighException">
        <dt>Message</dt>
        <dd>{{ currentReweighException.exceptionMessage || 'None' }}</dd>
        <dt>Resubmittable</dt>
        <dd>{{ currentReweighException.resubmittableInd || 'None' }}</dd>
        <dt>Expected</dt>
        <dd>{{ currentReweighException.expectedInd || 'None' }}</dd>
        <dt>Retry Count</dt>
        <dd>{{ currentReweighException.retryCount || 'None' }}</dd>
        <dt>Prompt Permitted</dt>
        <dd>{{ currentReweighException.promptPermittedInd || 'None' }}</dd>
        <dt>Status</dt>
        <dd>{{ currentReweighException.exceptionStatus || 'None' }}</dd>
        <dt>Big Query Category</dt>
        <dd>{{ currentReweighException.bigQueryCategory || 'None' }}</dd>
        <dt>Manual PDF</dt>
        <dd>{{ currentReweighException.manualPdfCreationInd || 'None' }}</dd>
        <dt>Auto PDF</dt>
        <dd>{{ currentReweighException.autoPdfCreationInd || 'None' }}</dd>
      </dl>
    </div>
  </ng-container>

  <!--list exceptions-->
  <div class="complete-exceptions-list">
    <div>
      <h1 *ngIf="isDatasourceSetted">
        Complete exceptions list
        <button
          mat-button
          xpoSmallButton
          matTooltip="Export to Excel"
          (click)="copyCsvToClipboard(exporter, MatTableExporterOutputFileType.XLSX)"
        >
          <mat-icon>download</mat-icon>
        </button>
        <button
          mat-button
          xpoSmallButton
          matTooltip="Export to CSV"
          (click)="copyCsvToClipboard(exporter, MatTableExporterOutputFileType.CSV)"
        >
          <mat-icon>download</mat-icon>
        </button>
      </h1>
      <h1 *ngIf="!isDatasourceSetted">Loading exceptions list</h1>
    </div>
    <div class="exceptions-table mat-elevation-z8">
      <mat-table
        matTableExporter
        #exporter="matTableExporter"
        [dataSource]="datasource"
        class="exceptions-list mat-elevation-z8"
        matSort
        matSortActive="exceptionCd"
        matSortDirection="asc"
      >
        <ng-container matColumnDef="exceptionCd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
          <td mat-cell *matCellDef="let element">{{ element.exceptionCd }}</td>
        </ng-container>
        <ng-container matColumnDef="exceptionMessage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
          <td mat-cell *matCellDef="let element">{{ element.exceptionMessage }}</td>
        </ng-container>
        <ng-container matColumnDef="exceptionStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.exceptionStatus }}</td>
        </ng-container>
        <ng-container matColumnDef="promptPermittedInd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Prompt Permitted</th>
          <td mat-cell *matCellDef="let element">{{ element.promptPermittedInd | yesNoOption }}</td>
        </ng-container>
        <ng-container matColumnDef="resubmittableInd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Resubmittable</th>
          <td mat-cell *matCellDef="let element">{{ element.resubmittableInd | yesNoOption }}</td>
        </ng-container>
        <ng-container matColumnDef="retryCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Max Retry Count</th>
          <td mat-cell *matCellDef="let element">{{ element.retryCount }}</td>
        </ng-container>
        <ng-container matColumnDef="expectedInd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Expected Ind</th>
          <td mat-cell *matCellDef="let element">{{ element.expectedInd | yesNoOption }}</td>
        </ng-container>
        <ng-container matColumnDef="bigQueryCategory">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Big Query Category</th>
          <td mat-cell *matCellDef="let element">{{ element.bigQueryCategory }}</td>
        </ng-container>
        <ng-container matColumnDef="detailsInHeaderInd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Error Details in Header</th>
          <td mat-cell *matCellDef="let element">{{ element.detailsInHeaderInd | yesNoOption }}</td>
        </ng-container>
        <ng-container matColumnDef="className">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Java Class Name</th>
          <td mat-cell *matCellDef="let element">{{ element.className }}</td>
        </ng-container>
        <ng-container matColumnDef="processedCorrectionInd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Processed Correction Ind</th>
          <td mat-cell *matCellDef="let element">{{ element.processedCorrectionInd | yesNoOption }}</td>
        </ng-container>
        <ng-container matColumnDef="manualPdfCreationInd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Manual PDF</th>
          <td mat-cell *matCellDef="let element">{{ element.manualPdfCreationInd | yesNoOption }}</td>
        </ng-container>
        <ng-container matColumnDef="autoPdfCreationInd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Auto PDF</th>
          <td mat-cell *matCellDef="let element">{{ element.autoPdfCreationInd | yesNoOption }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: visibleColumns"
          (click)="selection.toggle(row)"
          [ngClass]="{ hovered: row.hovered, highlightedTableRow: selection.isSelected(row) }"
          (mouseover)="row.hovered = true"
          (mouseout)="row.hovered = false"
        ></tr>
      </mat-table>
    </div>
  </div>
</div>
