import { IOperatorFirstnamLastname } from '@shared/classes/entities/operator-firstname-lastname';
import { UiEntityTableEntity } from '@shared/classes/entities/ui-entity-table-entity';
import { ProNumberHelper } from '@shared/classes/pro-number-helper/pro-number-helper';
import { ProNumberUtil } from '@shared/classes/utils/pro-number-util';
import { LiftScaleCalibrationHeader } from '@xpo-ltl/sdk-reweigh';
import { ScaleForkliftReport } from '../../../reweigh-calibration-headers/lib/scale-forklift-report';

export class ExtendedLiftScaleCalibrationHeader extends LiftScaleCalibrationHeader
  implements IOperatorFirstnamLastname, UiEntityTableEntity {
  operatorFullName: string;
  equipmentFullId: string;
  firstColumnId: string = '1';
  displayedChildProNbrTxt: string;

  static toExtendedLiftScaleCalibrationHeader(src: LiftScaleCalibrationHeader): ExtendedLiftScaleCalibrationHeader {
    let result: ExtendedLiftScaleCalibrationHeader = null;
    if (src) {
      result = new ExtendedLiftScaleCalibrationHeader();
      Object.assign(result, src); // Object.assign(target, source)
      result.setOperatorFullName();
      result.setFullEquipmentId();
      if (ProNumberUtil.isValidChildProNbrTxtAndNotNull(result.childProNbr)) {
        result.displayedChildProNbrTxt = ProNumberHelper.getStandardizedProNbr(result.childProNbr);
      }
    }
    return result;
  }

  setOperatorFullName() {
    if (this.operatorFirstName) {
      this.operatorFullName = this.operatorFirstName;
    }
    if (this.operatorLastName) {
      if (this.operatorFullName?.length > 0) {
        this.operatorFullName += ' ' + this.operatorLastName;
      }
    }
  }

  protected setFullEquipmentId() {
    this.equipmentFullId = ScaleForkliftReport.getEquipmentFullId(this.equipmentIdPrefix, this.equipmentIdSuffixNbr);
  }

  equals(aLiftScaleCalibrationHeader: ExtendedLiftScaleCalibrationHeader): boolean {
    const result: boolean = this.calibrationHeaderId === aLiftScaleCalibrationHeader.calibrationHeaderId;

    return result;
  }
}
