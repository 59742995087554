import { UiEntity } from '@shared/classes/entities/ui-entity';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { List } from 'immutable';

export class UiEntityUtil {
  static toVisibleColumnNames(
    aUiEntity: UiEntity,
    propertyNamesToExclude: string[] | null,
    collapsedOnly: boolean,
    expandedOnly: boolean
  ): List<string> {
    const result: string[] = [];
    const variableNameExcludesIsNull: boolean = !propertyNamesToExclude;
    if (!aUiEntity) {
      console.warn('given uiEntity is null');
    } else {
      if (aUiEntity?.properties?.length > 0) {
        let uiEntityProperties: UiEntityProperty[];
        if (expandedOnly) {
          uiEntityProperties = aUiEntity.getPropertiesSortedByExpandedColumnIndex();
        } else {
          uiEntityProperties = aUiEntity.getPropertiesSortedByCollapsedColumnIndex();
        }

        for (let i = 0; i < uiEntityProperties.length; i++) {
          const uiEntityProperty: UiEntityProperty = uiEntityProperties[i];
          const lPropertyName: string = uiEntityProperty.propertyName;
          if (
            uiEntityProperty.isVisibleColumn() &&
            (variableNameExcludesIsNull || propertyNamesToExclude?.indexOf(lPropertyName) === -1)
          ) {
            const isVisibleInExpanded: boolean = uiEntityProperty.uiEntityPropertyOptions.isVisibleInExpanded;
            const isVisibleInCollapsed: boolean = uiEntityProperty.uiEntityPropertyOptions.isVisibleInCollapsed;
            const isPropertyToAdd: boolean =
              (collapsedOnly && isVisibleInCollapsed) || (expandedOnly && isVisibleInExpanded);
            // console.debug('isVisibleInExpanded for '+ aUiEntity.entityTypeName
            // +' for ' + lPropertyName + ': addProp?' + isPropertyToAdd + '->' +
            //   ' visibleCollapsed, visibleExpanded, collapsedOnly, expandedOnly',
            //   isVisibleInCollapsed, isVisibleInExpanded, !!!collapsedOnly, !!!expandedOnly)
            // other if just for clarity...
            if (isPropertyToAdd) {
              result.push(lPropertyName);
            }
          }
        }
      }
    }
    // console.debug(aUiEntity.entityTypeName + ' result, for collapsed:' +
    //   collapsedOnly + ' expanded:' + expandedOnly + ':' + result.length + ':', result)
    return List(result);
  }

  static getGridServiceHeaderName(aPropertyName: string, uiEntityProperties: UiEntityProperty[]) {
    for (const property of uiEntityProperties) {
      if (property.propertyName === aPropertyName) {
        if (property.hasLabel()) {
          const result: string = property.getLabel();
          return result;
        }
      }
    }
    console.error('Requested property "' + aPropertyName + '" has not been found in entity', uiEntityProperties);
  }

  static getHeaderName(aPropertyName: string, uiEntityProperties: UiEntityProperty[]): string {
    let result: string;
    for (const property of uiEntityProperties) {
      if (property.propertyName === aPropertyName) {
        if (property.hasLabel()) {
          result = property.getLabel();
          break;
        }
      }
    }
    if (!result) {
      console.error('Property not found:' + aPropertyName + ' into:' + uiEntityProperties);
      throw Error('Property not found:' + aPropertyName);
    }

    return result;
  }
}
