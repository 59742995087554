import { Injectable } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { ErrorHelper } from '@shared/classes';
import { XpoNotificationTemplate, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { XpoSnackBarConfig } from '@xpo-ltl/ngx-ltl-core/lib/snack-bar/models/xpo-snack-bar-config.interface';

/**
 * https://github.xpo.com/OneXPO/ngx-core/tree/8b9e6b6085961b6e283c0757f24b91f4e1e76b7e/lib/snack-bar/src
 */
@Injectable({
  providedIn: 'root',
})
export class ReweighAppNotificationService {
  protected static readonly DEFAULT_DURATION: number = 7_000;

  constructor(protected xpoSnackBar: XpoSnackBar) {}

  error(e: any, snackBarOpenDurationMillis?: number) {
    this.xpoSnackBar.open({
      message: ErrorHelper.getErrorMessageFromAny(e),
      status: 'error',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis),
      },
      hideClose: false,
    });
  }

  warning(e: any, snackBarOpenDurationMillis?: number) {
    this.xpoSnackBar.open({
      message: ErrorHelper.getErrorMessageFromAny(e),
      status: 'warn',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis),
      },
    });
  }

  success(e: any, snackBarOpenDurationMillis?: number): void {
    this.xpoSnackBar.open({
      message: ErrorHelper.getErrorMessageFromAny(e),
      status: 'success',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis),
      },
    });
  }

  /**
   * milliseconds
   *
   * @param snackBarOpenDuration
   * @private
   */
  private getSnackBarDuration(snackBarOpenDuration: number): number {
    let result: number = ReweighAppNotificationService.DEFAULT_DURATION;
    if (!isNaN(snackBarOpenDuration)) {
      result = snackBarOpenDuration;
    }
    return result;
  }

  info(e: any, snackBarOpenDurationMillis?: number): void {
    this.xpoSnackBar.open({
      message: ErrorHelper.getErrorMessageFromAny(e),
      status: 'success',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis),
      },
      hideClose: false,
    });
  }

  open(param: XpoSnackBarConfig): MatSnackBarRef<XpoNotificationTemplate> {
    return this.xpoSnackBar.open(param);
  }
}
