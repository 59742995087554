import { ErrorHelper } from '@shared/classes';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { UserRoleService } from '@shared/services/user-role';
import { BaseService, DataOptions } from '@xpo-ltl/data-api';
import { Envelope } from '@xpo-ltl/sdk-common';
import { Observable, throwError } from 'rxjs';

export abstract class ApiServiceWrapper<TapiService extends BaseService> {
  constructor(
    protected baseService: TapiService,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected userRoleService: UserRoleService
  ) {}

  protected getSimpleDataOptions(): DataOptions {
    return {
      toastOnError: false,
      loadingOverlayEnabled: true,
    };
  }

  protected showNoWriteAccess(methodName: string) {
    const msg: string = methodName + '-> you dont have access to that, but only read access';
    this.reweighAppNotificationService.error(msg, 12_000);
    console.error('Error:', msg);
  }

  protected validateCurrentUserHasWriteAccess(methodName: string) {
    if (!this.userRoleService.currentUserHasWriteAccess()) {
      this.showNoWriteAccess(methodName);
    }
  }

  protected handleError(error: any): Observable<any> {
    const message = ErrorHelper.getErrorMessageFromAny(error);

    this.reweighAppNotificationService.error(message);
    console.error('Error:', error, message);

    return throwError(error);
  }
}
