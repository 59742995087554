export class ErrorHelper {
  private static readonly defaultMessage = 'Error processing the request.';

  static getErrorMessageFromAny(aError: any): string {
    let result: string;

    if (aError) {
      if (aError.error?.error?.moreInfo?.length > 0) {
        const lMoreInfoFirst: any = aError.error.error.moreInfo[0];
        result = `${lMoreInfoFirst.message || lMoreInfoFirst.location}`;
      } else if (aError.moreInfo?.length > 0) {
        result = `${aError.errorCode}: ${aError.moreInfo[0].message || aError.moreInfo[0].location}`;
      } else if (aError.errorCode?.length > 0 && aError.message?.length) {
        result = `${aError.errorCode}: ${aError.message}`;
      } else {
        result = JSON.stringify(aError);
      }
    }

    return result || this.defaultMessage;
  }
}
